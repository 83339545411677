@use 'sass:map';
@use '@angular/material' as mat;
@import '../material-utils';

@mixin color($theme) {
	.autocomplete {
		.mat-mdc-form-field.readonly,
		.mat-mdc-form-field.readonly.mat-form-field-disabled {
			opacity: 1;

			&.multiple .mat-mdc-form-field-infix {
				opacity: 0;
			}

			.mdc-text-field--filled .mdc-text-field__input,
			.mdc-text-field--filled.mdc-text-field--disabled .mdc-text-field__input {
				color: inherit;
			}
		}

		.mat-icon {
			color: var(--mat-icon-color);
		}
	}

	.cdk-overlay-container {
		div.mat-mdc-autocomplete-panel,
		divmat-mdc-select-panel.mat-mdc-select-panel,
		div.mat-mdc-select-panel {
			border: var(--vis-line-strength-thin) solid var(--vis-border-color-modal);
			box-shadow: var(--box-shadow-large);
			background-color: var(--vis-color-bg-select);
			padding: 0;

			mat-option.mat-mdc-option {
				color: var(--vis-color-text-default);
				min-height: calc(var(--vis-gu) * 4);
				padding: 0 calc(var(--vis-gu) * 1);

				&:hover {
					background: var(--vis-color-bg-select-hover) !important;
				}

				&:active {
					background: var(--vis-color-interactive-element-bg-default-hovered) !important;
				}

				.mdc-list-item__primary-text {
					color: inherit;
				}

				&.mat-option__multiple-items {
					flex-direction: column;
					padding: 0;

					.mdc-list-item__primary-text {
						display: flex;
						flex-direction: row;
						height: 100%;
						flex: 1;
						width: 100%;
					}

					.mat-option__checkbox-wrap {
						width: 100%;
						display: flex;
						align-items: center;
						padding: 0 calc(var(--vis-gu));
					}
				}

				&.mdc-list-item--selected:not(.mat-mdc-option-multiple) {
					.mat-pseudo-checkbox {
						display: none;
					}
				}

				&.mat-mdc-option-active.mdc-list-item {
					background-color: var(--vis-color-bg-select-hover) !important;
				}

				&.mdc-list-item--selected:not(.mat-mdc-option-multiple):not(.mdc-list-item--disabled):not(
						.mat-mdc-option-active
					) {
					background: var(--vis-color-bg-select-active) !important;
				}

				.mat-ripple-element {
					display: none;
				}
			}

			@if isDarkTheme($theme) {
				mat-option.mat-mdc-option {
					color: var(--vis-color-lightgray);

					&.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
						color: var(--vis-color-lightgray);
					}
				}
			}
		}
	}
}

@mixin typography($theme) {
	$typography-config: mat.m2-get-typography-config($theme);
	$font-family: mat.m2-font-family($typography-config);

	.mat-mdc-form-field-label {
		font: var(--vis-typography-body);
	}
}

@mixin theme($theme) {
	$color-config: mat.m2-get-color-config($theme);

	@if $color-config != null {
		@include color($theme);
	}

	$typography-config: mat.m2-get-typography-config($theme);

	@if $typography-config != null {
		@include typography($theme);
	}
}
