@use 'sass:map';
@use '@angular/material' as mat;
@import '../material-utils';

@mixin color($theme) {
	.mdc-list.mdc-list {
		--mdc-list-list-item-label-text-color: var(--vis-color-text-default);
		--mdc-list-list-item-supporting-text-color: var(--vis-color-text-default);
		--mdc-list-list-item-leading-icon-color: var(--vis-color-text-default);
		--mdc-list-list-item-trailing-supporting-text-color: var(--vis-color-text-default);
		--mdc-list-list-item-trailing-icon-color: var(--vis-color-text-default);
		--mdc-list-list-item-selected-trailing-icon-color: var(--vis-color-text-default);
		--mdc-list-list-item-disabled-label-text-color: var(--vis-color-text-default);
		--mdc-list-list-item-disabled-leading-icon-color: var(--vis-color-text-default);
		--mdc-list-list-item-disabled-trailing-icon-color: var(--vis-color-text-default);
		--mdc-list-list-item-hover-label-text-color: var(--vis-color-text-default);
		--mdc-list-list-item-hover-leading-icon-color: var(--vis-color-text-default);
		--mdc-list-list-item-hover-trailing-icon-color: var(--vis-color-text-default);
		--mdc-list-list-item-focus-label-text-color: var(--vis-color-text-default);
		--mdc-list-list-item-hover-state-layer-color: var(
			--vis-color-first-hierarchy-bg-default-hovered
		);
		--mdc-list-list-item-hover-state-layer-opacity: 1;
		--mdc-list-list-item-focus-state-layer-color: var(
			--vis-color-first-hierarchy-bg-default-pressed
		);
		--mdc-list-list-item-focus-state-layer-opacity: 1;

		--mdc-list-list-item-container-shape: 0;
		--mdc-list-list-item-leading-avatar-shape: 50%;
		--mdc-list-list-item-container-color: transparent;
		--mdc-list-list-item-selected-container-color: var(
			--vis-color-first-hierarchy-bg-active-enabled
		);
		--mdc-list-list-item-leading-avatar-color: transparent;
		--mdc-list-list-item-leading-icon-size: 24px;
		--mdc-list-list-item-leading-avatar-size: 40px;
		--mdc-list-list-item-trailing-icon-size: 24px;
		--mdc-list-list-item-disabled-state-layer-color: transparent;
		--mdc-list-list-item-disabled-state-layer-opacity: 0;
		--mdc-list-list-item-disabled-label-text-opacity: 1;
		--mdc-list-list-item-disabled-leading-icon-opacity: 1;
		--mdc-list-list-item-disabled-trailing-icon-opacity: 1;
		--mat-list-active-indicator-color: transparent;
		--mat-list-active-indicator-shape: 0;

		--mdc-list-list-item-one-line-container-height: calc(var(--vis-gu) * 6);
		--mdc-list-list-item-two-line-container-height: calc(var(--vis-gu) * 9);
		--mdc-list-list-item-three-line-container-height: calc(var(--vis-gu) * 12.5);
		--mat-list-list-item-leading-icon-start-space: calc(var(--vis-gu) * 2);
		--mat-list-list-item-leading-icon-end-space: calc(var(--vis-gu) * 4);

		max-width: 100%;
		padding: 0;

		&.mat-mdc-selection-list:not(.vis-integrated) {
			padding: calc(var(--vis-gu) * 2) calc(var(--vis-gu) * 2);
			border: 1px solid var(--vis-border-color);
			border-bottom: none;
		}

		&.vis-integrated {
			.mat-mdc-list-item {
				padding: calc(var(--vis-gu) * 2) calc(var(--vis-gu) * 4);
			}
		}

		// Radio button

		.mdc-list-item__start,
		.mdc-list-item__end {
			--mdc-radio-state-layer-size: calc(var(--vis-gu) * 2);
			--mdc-radio-disabled-selected-icon-color: var(--vis-border-color-hover);
			--mdc-radio-disabled-unselected-icon-color: var(--vis-border-color);

			--mdc-radio-unselected-hover-icon-color: var(--vis-border-color);
			--mdc-radio-unselected-icon-color: var(--vis-border-color);
			--mdc-radio-unselected-pressed-icon-color: var(--vis-border-color);

			--mdc-radio-selected-focus-icon-color: var(--vis-border-color-hover);
			--mdc-radio-selected-hover-icon-color: var(--vis-border-color-hover);
			--mdc-radio-selected-icon-color: var(--vis-border-color-hover);
			--mdc-radio-selected-pressed-icon-color: var(--vis-border-color-hover);
		}

		&.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
		&.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text {
			color: var(--vis-color-text-default);
			font-weight: var(--vis-label2-font-weight);
		}
	}

	.mdc-list-item__start {
		margin-left: 0;
	}

	.mdc-list-item__end {
		margin-right: 0 !important;
	}

	.mdc-list-item,
	.mat-mdc-list-item {
		padding-left: var(--vis-gu);

		&.mdc-list-item--disabled {
			opacity: var(--vis-disabled-opacity);
		}

		&.mdc-list-item--selected,
		&.mdc-list-item--activated {
			--mdc-list-list-item-container-color: var(--vis-color-first-hierarchy-bg-active-enabled);

			&,
			&.mdc-list-item__primary-text {
				color: var(--vis-color-text-default) !important;
			}
		}

		&.mdc-list-item--with-three-lines
			.mat-mdc-list-item-unscoped-content.mdc-list-item__secondary-text {
			display: -webkit-box;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 3;
		}

		&::before {
			z-index: -1;
		}
	}

	.mat-mdc-list-option.mat-mdc-list-option,
	.mat-mdc-list-item-interactive {
		border-bottom: 1px solid var(--vis-border-color);

		.mdc-checkbox.mdc-checkbox {
			--mdc-checkbox-state-layer-size: calc(var(--vis-gu) * 2);

			width: var(--mdc-checkbox-state-layer-size);
			height: var(--mdc-checkbox-state-layer-size);
			font-size: var(--mdc-checkbox-state-layer-size);
			line-height: var(--mdc-checkbox-state-layer-size);
			display: block;

			.mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not(
					[data-indeterminate='true']
				)
				~ .mdc-checkbox__background {
				background: var(--vis-color-interactive-element-bg-default-enabled);
			}
		}

		.mdc-radio {
			width: var(--mdc-radio-state-layer-size);
			height: var(--mdc-radio-stat-layer-size);
			font-size: var(--mdc-radio-state-layer-size);
			line-height: var(--mdc-radio-state-layer-size);
			margin-top: var(--vis-gu);
		}

		.mdc-radio__background {
			width: var(--mdc-radio-state-layer-size);
			height: var(--mdc-radio-state-layer-size);
		}

		.mdc-radio__outer-circle {
			border-width: 1px;
			background: var(--vis-color-interactive-element-bg-default-enabled);
		}

		.mdc-radio__inner-circle {
			border-width: var(--vis-gu);
			border-color: var(--vis-color-text-on-dark-bg) !important;
		}

		.mdc-radio--disabled {
			&,
			& + .mdc-label {
				opacity: var(--vis-disabled-opacity);
			}
		}

		// Background in selected state
		.mdc-radio
			.mdc-radio__native-control:enabled:checked
			+ .mdc-radio__background
			.mdc-radio__outer-circle {
			background: var(--vis-color-interactive-element-bg-activated-enabled);
		}

		// Background in selected hovered state
		.mdc-radio:hover
			.mdc-radio__native-control:enabled:checked
			+ .mdc-radio__background
			.mdc-radio__outer-circle {
			background: var(--vis-color-interactive-element-bg-activated-hovered);
		}

		// Background in selected disabled state
		.mdc-radio
			.mdc-radio__native-control:disabled:checked
			+ .mdc-radio__background
			.mdc-radio__outer-circle {
			background: var(--vis-color-interactive-element-bg-activated-enabled);
		}

		// Background in unselected hovered state
		.mdc-radio:hover
			.mdc-radio__native-control:enabled:not(:checked)
			+ .mdc-radio__background
			.mdc-radio__outer-circle {
			background: var(--vis-color-interactive-element-bg-default-hovered);
		}

		.mdc-form-field {
			color: var(--vis-color-text-default);

			&:hover {
				// Background in selected hovered state
				.mdc-radio
					.mdc-radio__native-control:enabled:checked
					+ .mdc-radio__background
					.mdc-radio__outer-circle {
					background: var(--vis-color-interactive-element-bg-activated-hovered);
				}

				// Background in unselected hovered state
				.mdc-radio
					.mdc-radio__native-control:enabled:not(:checked)
					+ .mdc-radio__background
					.mdc-radio__outer-circle {
					background: var(--vis-color-interactive-element-bg-default-hovered);
				}
			}
		}

		.mdc-radio:not(.mdc-radio--disabled) + label:hover {
			cursor: pointer;
		}
	}
}

@mixin typography($theme) {
}

@mixin theme($theme) {
	$color-config: mat.m2-get-color-config($theme);

	@if $color-config != null {
		@include color($theme);
	}

	$typography-config: mat.m2-get-typography-config($theme);

	@if $typography-config != null {
		@include typography($theme);
	}
}
