@use 'sass:map';
@use '@angular/material' as mat;
@import '../material-utils';

@mixin color($theme) {
	.mat-mdc-tab-group,
	.mat-mdc-tab-nav-bar {
		--mat-tab-header-disabled-ripple-color: transparent;
		--mat-tab-header-active-ripple-color: transparent;
		--mat-tab-header-inactive-ripple-color: transparent;

		--mat-tab-header-pagination-icon-color: var(--vis-color-icon-interactive-bg-default-enabled);

		--mat-tab-header-inactive-label-text-color: var(--vis-color-menu-tab-item-default);
		--mat-tab-header-inactive-hover-label-text-color: var(--vis-color-menu-tab-item-default);
		--mat-tab-header-inactive-focus-label-text-color: var(--vis-color-menu-tab-item-default);

		--mat-tab-header-active-label-text-color: var(--vis-color-menu-tab-item-selected);
		--mat-tab-header-active-hover-label-text-color: var(--vis-color-menu-tab-item-selected);
		--mat-tab-header-active-focus-label-text-color: var(--vis-color-menu-tab-item-selected);
		// Indicator
		--mdc-tab-indicator-active-indicator-color: var(
			--vis-color-menu-tab-item-selected-border-bottom
		);
		--mat-tab-header-active-focus-indicator-color: var(
			--vis-color-menu-tab-item-selected-border-bottom
		);
		--mat-tab-header-active-hover-indicator-color: var(
			--vis-color-menu-tab-item-selected-border-bottom
		);
		--mdc-tab-indicator-active-indicator-height: calc(var(--vis-gu) * 0.5);
		--mdc-tab-indicator-active-indicator-shape: 0;

		.mat-mdc-tab-header,
		.mat-mdc-tab-link-container {
			--mdc-secondary-navigation-tab-container-height: calc(var(--vis-gu) * 6);

			margin-bottom: var(--vis-gu);
		}

		.mdc-tab {
			min-width: calc(var(--vis-gu) * 5);
			padding: 0 calc(var(--vis-gu) * 2);
			background: var(--vis-color-menu-tab-item-default-background);

			&:hover {
				background: var(--vis-color-menu-tab-item-default-background-hover);
			}

			&:focus {
				background: var(--vis-color-menu-tab-item-default-background-focus);
			}

			&:active {
				background: var(--vis-color-menu-tab-item-default-background-active);
			}

			&.mdc-tab--active {
				background: var(--vis-color-menu-tab-item-selected-background);
			}
		}

		.mat-mdc-tab.mat-mdc-tab-disabled {
			opacity: var(--vis-disabled-opacity);
		}

		&[vis-size='m'] {
			--mdc-tab-indicator-active-indicator-height: calc(var(--vis-gu) * 0.75);
			--mdc-tab-indicator-active-indicator-shape: 0;

			.mat-mdc-tab-header {
				--mdc-secondary-navigation-tab-container-height: calc(var(--vis-gu) * 8);
			}

			.mdc-tab {
				padding: 0 calc(var(--vis-gu) * 4);
			}
		}

		&[vis-type='content'] {
			--mdc-secondary-navigation-tab-container-height: calc(var(--vis-gu) * 4);

			&[vis-size='m'] .mat-mdc-tab-header {
				--mdc-secondary-navigation-tab-container-height: calc(var(--vis-gu) * 6);
			}
			// Indicator
			--mdc-tab-indicator-active-indicator-color: transparent;
			--mat-tab-header-active-focus-indicator-color: transparent;
			--mat-tab-header-active-hover-indicator-color: transparent;
			--mdc-tab-indicator-active-indicator-height: 0;
			//Label
			--mat-tab-header-inactive-label-text-color: var(--vis-color-gs-85);
			--mat-tab-header-inactive-hover-label-text-color: var(--vis-color-gs-100);
			--mat-tab-header-inactive-focus-label-text-color: var(--vis-color-gs-100);
			//Active label
			--mat-tab-header-active-label-text-color: var(--vis-color-gs-120);
			--mat-tab-header-active-hover-label-text-color: var(--vis-color-gs-120);
			--mat-tab-header-active-focus-label-text-color: var(--vis-color-gs-120);
			// Background
			--vis-color-menu-tab-item-default-background-active: var(--vis-color-gs-10);
			--vis-color-menu-tab-item-default-background-hover: var(--vis-color-gs-40);
			--vis-color-menu-tab-item-default-background-focus: var(--vis-color-gs-10);
			--vis-color-menu-tab-item-default-background: var(--vis-color-gs-30);

			@if isDarkTheme($theme) {
				//Label
				--mat-tab-header-inactive-label-text-color: var(--vis-color-gs-80);
				--mat-tab-header-inactive-hover-label-text-color: var(--vis-color-gs-50);
				--mat-tab-header-inactive-focus-label-text-color: var(--vis-color-gs-50);
				//Active label
				--mat-tab-header-active-label-text-color: var(--vis-color-gs-10);
				--mat-tab-header-active-hover-label-text-color: var(--vis-color-gs-10);
				--mat-tab-header-active-focus-label-text-color: var(--vis-color-gs-10);
				// Background
				--vis-color-menu-tab-item-default-background-active: var(--vis-color-gs-100);
				--vis-color-menu-tab-item-default-background-hover: var(--vis-color-gs-115);
				--vis-color-menu-tab-item-default-background-focus: var(--vis-color-gs-120);
				--vis-color-menu-tab-item-default-background: var(--vis-color-gs-110);
			}

			.mat-mdc-tab-header {
				--mdc-secondary-navigation-tab-container-height: calc(var(--vis-gu) * 4);

				text-transform: uppercase;
				margin-bottom: 0;
			}
		}

		&[vis-type='content-highlight'] {
			&,
			&[vis-size='m'] .mat-mdc-tab-header {
				--mdc-secondary-navigation-tab-container-height: calc(var(--vis-gu) * 6);
			}
			// Indicator
			--vis-color-menu-tab-item-selected-border-bottom: var(--vis-color-gs-120);
			--mdc-tab-indicator-active-indicator-height: 2px;
			// Label
			--mat-tab-header-inactive-label-text-color: var(--vis-color-text-default);
			--mat-tab-header-inactive-hover-label-text-color: var(--vis-color-text-default);
			--mat-tab-header-inactive-focus-label-text-color: var(--vis-color-text-default);
			//Active label
			--mat-tab-header-active-label-text-color: var(--vis-color-gs-120);
			--mat-tab-header-active-hover-label-text-color: var(--vis-color-gs-120);
			--mat-tab-header-active-focus-label-text-color: var(--vis-color-gs-120);
			// Background
			--vis-color-menu-tab-item-default-background-active: var(--vis-color-gs-10);
			--vis-color-menu-tab-item-default-background-hover: var(--vis-color-gs-40);
			--vis-color-menu-tab-item-default-background-focus: var(--vis-color-gs-10);
			--vis-color-menu-tab-item-default-background: var(--vis-color-gs-30);

			@if isDarkTheme($theme) {
				// Indicator
				--vis-color-menu-tab-item-selected-border-bottom: var(--vis-color-pb-90);
				//Label
				--mat-tab-header-inactive-label-text-color: var(--vis-color-gs-50);
				--mat-tab-header-inactive-hover-label-text-color: var(--vis-color-gs-50);
				--mat-tab-header-inactive-focus-label-text-color: var(--vis-color-gs-50);
				//Active label
				--mat-tab-header-active-label-text-color: var(--vis-color-gs-10);
				--mat-tab-header-active-hover-label-text-color: var(--vis-color-gs-10);
				--mat-tab-header-active-focus-label-text-color: var(--vis-color-gs-10);
				// Background
				--vis-color-menu-tab-item-default-background-active: var(--vis-color-gs-100);
				--vis-color-menu-tab-item-default-background-hover: var(--vis-color-gs-115);
				--vis-color-menu-tab-item-default-background-focus: var(--vis-color-gs-120);
				--vis-color-menu-tab-item-default-background: var(--vis-color-gs-110);
			}

			.mat-mdc-tab-header {
				margin-bottom: 0;
			}

			.mdc-tab {
				padding: 0 calc(var(--vis-gu) * 4);
			}

			.mdc-tab-indicator__content--underline {
				height: 100%;
			}
		}
	}

	.mat-tab-body-wrapper {
		height: 100%;
	}
}

@mixin typography($theme) {
	.mat-mdc-tab-group,
	.mat-mdc-tab-nav-bar {
		font: var(--vis-typography-body);

		.mat-mdc-tab-labels.mat-mdc-tab-labels,
		.mat-mdc-tab-links.mat-mdc-tab-links {
			--mat-tab-header-label-text-font: var(--vis-font-family);
			--mat-tab-header-label-text-size: var(--vis-caption-font-size);
			--mat-tab-header-label-text-line-height: var(--vis-caption-line-height);
			--mat-tab-header-label-text-weight: var(--vis-caption-font-weight);
			--mat-tab-header-label-text-tracking: 0;

			.mdc-tab-indicator--active {
				--mat-tab-header-label-text-weight: var(--vis-label1-font-weight);
			}
		}

		&[vis-size='m'],
		&[vis-type='content-highlight'] {
			.mat-mdc-tab-labels.mat-mdc-tab-labels,
			.mat-mdc-tab-links.mat-mdc-tab-links {
				--mat-tab-header-label-text-size: var(--vis-body-font-size);
				--mat-tab-header-label-text-line-height: var(--vis-body-line-height);
				--mat-tab-header-label-text-weight: var(--vis-body-font-weight);
			}
		}

		.mat-mdc-tab-header-pagination {
			min-width: calc(var(--vis-gu) * 2.2);
		}

		.mat-mdc-tab-header-pagination-before,
		.mat-mdc-tab-header-rtl .mat-mdc-tab-header-pagination-after {
			padding-left: 0;
			padding-right: 3px;
		}

		.mat-mdc-tab-header-rtl .mat-mdc-tab-header-pagination-before,
		.mat-mdc-tab-header-pagination-after {
			padding-right: 0;
			padding-left: 3px;
		}

		&[vis-type='content'] {
			&,
			&[vis-size='m'] {
				.mat-mdc-tab-labels.mat-mdc-tab-labels,
				.mat-mdc-tab-links.mat-mdc-tab-links,
				.mdc-tab__text-label {
					--mat-tab-header-label-text-size: var(--vis-label2-font-size);
					--mat-tab-header-label-text-weight: var(--vis-label2-font-weight);

					.mdc-tab-indicator--active {
						--mat-tab-header-label-text-weight: var(--vis-label2-font-weight);
					}
				}
			}
		}
	}
}

@mixin theme($theme) {
	$color-config: mat.m2-get-color-config($theme);

	@if $color-config != null {
		@include color($theme);
	}

	$typography-config: mat.m2-get-typography-config($theme);

	@if $typography-config != null {
		@include typography($theme);
	}
}
