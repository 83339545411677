@use 'sass:map';
@use '@angular/material' as mat;
@import '../material-utils';

@mixin color($theme) {
	$color-config: mat.m2-get-color-config($theme);
	$primary-palette: map.get($color-config, 'primary');

	.mat-mdc-slider.mat-mdc-slider {
		--mat-slider-ripple-color: transparent;
		--mat-slider-hover-state-layer-color: transparent;
		--mat-slider-focus-state-layer-color: transparent;
		--mdc-slider-handle-color: var(--vis-color-slider-handle-fill-enabled);
		--mdc-slider-disabled-handle-color: var(--vis-color-slider-handle-fill-enabled);

		--mdc-slider-with-overlap-handle-outline-color: var(--vis-color-accent);

		--mdc-slider-focus-handle-color: var(--vis-color-slider-handle-fill-hover);
		--mdc-slider-hover-handle-color: var(--vis-color-slider-handle-fill-hover);
		--mdc-slider-active-track-color: var(--vis-color-slider-active-line-enabled);
		--mdc-slider-inactive-track-color: var(--vis-color-slider-track-enabled);

		--mdc-slider-with-tick-marks-inactive-container-color: var(--vis-color-slider-track-enabled);
		--mdc-slider-with-tick-marks-active-container-color: var(
			--vis-color-slider-active-line-readonly-enabled
		);
		--mdc-slider-with-tick-marks-disabled-container-color: var(
			--vis-color-slider-active-line-enabled
		);

		--mdc-slider-active-track-height: 2px;
		--mdc-slider-inactive-track-height: 2px;
		--mdc-slider-handle-height: calc(var(--vis-gu) * 2);
		--mdc-slider-handle-width: calc(var(--vis-gu) * 2);
		--mdc-slider-handle-shape: 50%;

		--mat-stepper-header-selected-state-icon-background-color: var(--vis-color-text-box-bg);
		--mat-stepper-header-selected-state-icon-foreground-color: var(--vis-color-text-default);

		--mdc-slider-label-container-color: var(--vis-color-text-box-bg);
		--mdc-slider-label-label-text-color: var(--vis-color-text-default);
		--mdc-slider-label-container-opacity: 1;
		--mdc-slider-value-indicator-opacity: 1;

		--mat-slider-value-indicator-height: 32px;
		--mat-slider-value-indicator-caret-display: block;
		--mat-slider-value-indicator-border-radius: 4px;
		--mat-slider-value-indicator-padding: 0 12px;
		--mat-slider-value-indicator-text-transform: none;
		--mat-slider-value-indicator-container-transform: translateX(-50%);
		--mdc-slider-with-overlap-handle-outline-width: 1px;
		--mdc-slider-with-tick-marks-active-container-opacity: 1;
		--mdc-slider-with-tick-marks-container-shape: 1px;
		--mdc-slider-with-tick-marks-container-size: 1px;
		--mdc-slider-with-tick-marks-inactive-container-opacity: 1;

		--mat-slider-value-indicator-opacity: 1;

		margin-left: 0;
		margin-right: 0;
		width: 100%;

		.mdc-slider__thumb-knob {
			box-shadow: none;
			border: 2px solid var(--mdc-slider-active-track-color) !important;
			width: var(--mdc-slider-handle-width);
			height: var(--mdc-slider-handle-height);
		}

		.mdc-slider__thumb--focused {
			.mdc-slider__thumb-knob {
				border: 2px solid var(--mdc-slider-active-track-color) !important;
				--mdc-slider-handle-color: var(--vis-color-slider-handle-fill-hover);
			}
		}

		.mdc-slider__value-indicator {
			border: var(--vis-line-strength-thin) solid var(--vis-border-color);

			&::before {
				display: none;
			}

			&-text {
				font: var(--vis-typography-caption) !important;
			}

			&-container {
				bottom: calc(var(--vis-gu) * 5);
			}
		}

		.mat-ripple {
			display: none;
		}

		.mdc-slider__tick-mark--active,
		.mdc-slider__tick-mark--inactive {
			height: calc(var(--mdc-slider-with-tick-marks-container-size) * 5) !important;
			width: var(--mdc-slider-with-tick-marks-container-size);
			top: 3px;
			opacity: 1;
		}

		.mdc-slider__track--inactive {
			opacity: 1;
		}
	}
}

@mixin typography($theme) {
}

@mixin theme($theme) {
	$color-config: mat.m2-get-color-config($theme);

	@if $color-config != null {
		@include color($theme);
	}

	$typography-config: mat.m2-get-typography-config($theme);

	@if $typography-config != null {
		@include typography($theme);
	}
}
