@use 'sass:map';
@use '@angular/material' as mat;
@import '../material-utils';

:root .cdk-overlay-container .mdc-menu-surface.mat-mdc-select-panel {
	--mat-select-panel-background-color: var(--vis-color-second-hierarchy-bg-default-enabled);
	--mat-option-selected-state-label-text-color: var(--vis-color-text-default);
	--mat-option-label-text-color: var(--vis-color-text-default);
	--mat-option-hover-state-layer-color: var(--vis-color-second-hierarchy-bg-default-hovered);
	--mat-option-focus-state-layer-color: var(--vis-color-second-hierarchy-bg-default-hovered);
	--mat-option-selected-state-layer-color: var(--vis-color-second-hierarchy-bg-default-pressed);

	--mdc-list-list-item-container-color: var(--vis-color-second-hierarchy-bg-default-enabled);
	--mdc-list-list-item-selected-container-color: var(
		--vis-color-second-hierarchy-bg-default-hovered
	);

	&.mat-mdc-select-panel {
		--mat-select-container-elevation-shadow: var(--vis-box-shadow-default);
		border: var(--vis-border-color) 1px solid;
		//border-top: none;
		background: var(--mat-select-panel-background-color);
	}
}

@mixin color($theme) {
	$color-config: mat.m2-get-color-config($theme);
	$primary-palette: map.get($color-config, 'primary');

	.mat-mdc-option.mat-mdc-option {
		padding-left: var(--vis-gu);
		padding-right: var(--vis-gu);
		min-height: calc(var(--vis-gu) * 4);

		.mdc-list-item__primary-text + .mat-pseudo-checkbox.mat-mdc-option-pseudo-checkbox {
			display: none;
		}
	}

	.mat-mdc-optgroup-label.mat-mdc-optgroup-label {
		--vis-menu-divider-height: calc(var(--vis-gu) * 4);
		--vis-menu-divider-line-padding: calc(var(--vis-gu) * 1.5);
		--vis-menu-divider-line-position: 50%;
		--vis-menu-divider-line-color: var(--vis-border-color);
		--vis-menu-divider-line-height: var(--vis-line-strength-thin);

		display: flex;
		position: relative;
		align-items: center;
		justify-content: center;
		overflow: hidden;
		padding: 0 var(--vis-gu);
		min-height: calc(var(--vis-gu) * 4);
		width: 100%;

		.mdc-list-item__primary-text {
			position: relative;
			display: inline-block;
			max-width: calc(100% - var(--vis-menu-divider-line-padding) * 2);
			min-height: var(--vis-menu-divider-line-height);
			padding: 0 var(--vis-menu-divider-line-padding);
			overflow: visible;
			color: inherit;

			&::before,
			&::after {
				content: '';
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				width: 2000vw;
				height: var(--vis-menu-divider-line-height);
				background-color: var(--vis-menu-divider-line-color);
			}

			&::before {
				left: 100%;
			}

			&::after {
				right: 100%;
			}
		}
	}
}

@mixin typography($theme) {
	.mat-mdc-optgroup.mat-mdc-optgroup {
		--mat-optgroup-label-text-font: var(--vis-font-family);
		--mat-optgroup-label-text-line-height: var(--vis-body-line-height);
		--mat-optgroup-label-text-size: var(--vis-body-font-size);
		--mat-optgroup-label-text-tracking: 0;
		--mat-optgroup-label-text-weight: var(--vis-body-font-weight);
	}

	.mat-mdc-select-placeholder.mat-mdc-select-placeholder {
		--mat-select-placeholder-text-color: var(--vis-color-placeholder-input);
	}

	.mat-mdc-select.mat-mdc-select {
		--mat-select-enabled-trigger-text-color: var(--vis-color-text-default);

		.mat-mdc-select-min-line {
			font-family: var(--vis-font-family);
		}

		.mat-mdc-select-arrow-wrapper {
			height: 20px;
		}
	}

	.mat-mdc-optgroup-label.mat-mdc-optgroup-label {
		--mat-optgroup-label-text-color: var(--vis-color-text-divider);
		--mat-optgroup-label-text-font: var(--vis-label2-font-family);
		--mat-optgroup-label-text-line-height: var(--vis-label2-line-height);
		--mat-optgroup-label-text-size: var(--vis-label2-font-size);
		--mat-optgroup-label-text-tracking: 0;
		--mat-optgroup-label-text-weight: var(--vis-label2-font-weight);
		font: var(--vis-typography-label2);
		color: var(--vis-color-text-divider);
		text-transform: uppercase;
	}
}

@mixin theme($theme) {
	$color-config: mat.m2-get-color-config($theme);

	@if $color-config != null {
		@include color($theme);
	}

	$typography-config: mat.m2-get-typography-config($theme);

	@if $typography-config != null {
		@include typography($theme);
	}
}
