@use 'sass:map';
@use '@angular/material' as mat;
@import '../material-utils';

:root {
	// Switch toggle
	--vis-color-toggle-switch-default-background-color: var(--vis-color-gs-50);
	--vis-color-toggle-switch-default-background-color-disabled: var(--vis-color-gs-50);
	--vis-color-toggle-switch-default-background-color-hover: var(--vis-color-gs-40);
	--vis-color-toggle-switch-default-background-color-focus: var(--vis-color-gs-50);
	--vis-color-toggle-switch-default-background-color-active: var(--vis-color-gs-60);
	--vis-color-toggle-switch-default-border-color: var(--vis-color-gs-70);
	--vis-color-toggle-switch-default-checked-border-color: var(--vis-color-gs-90);
	--vis-color-toggle-switch-default-checked-background-color: var(--vis-color-gs-80);
	--vis-color-toggle-switch-default-checked-background-color-hover: var(--vis-color-gs-70);
	--vis-color-toggle-switch-default-checked-background-color-disabled: var(--vis-color-gs-80);
	--vis-color-toggle-switch-default-checked-background-color-focus: var(--vis-color-gs-80);
	--vis-color-toggle-switch-default-checked-background-color-active: var(--vis-color-gs-90);
	// Switch toggle accent color
	--vis-color-toggle-switch-primary-checked-background-color: var(--vis-color-pb-90);
	--vis-color-toggle-switch-primary-checked-background-color-hover: var(--vis-color-pb-90);
	--vis-color-toggle-switch-primary-checked-background-color-focus: var(--vis-color-pb-100);
	--vis-color-toggle-switch-primary-checked-background-color-active: var(--vis-color-pb-110);
	--vis-color-toggle-switch-primary-checked-border-color: var(--vis-color-pb-120);
	--vis-color-toggle-switch-primary-checked-border-color-disabled: var(--vis-color-pb-120);
}

html[theme='dark'] {
	--vis-color-toggle-switch-default-background-color: var(--vis-color-gs-110);
	--vis-color-toggle-switch-default-background-color-disabled: var(--vis-color-gs-110);
	--vis-color-toggle-switch-default-background-color-hover: var(--vis-color-gs-115);
	--vis-color-toggle-switch-default-background-color-focus: var(--vis-color-gs-110);
	--vis-color-toggle-switch-default-background-color-active: var(--vis-color-gs-120);
	--vis-color-toggle-switch-default-border-color: var(--vis-color-gs-120);
	--vis-color-toggle-switch-default-border-color-disabled: var(--vis-color-gs-120);
	--vis-color-toggle-switch-default-checked-background-color: var(--vis-color-gs-90);
	--vis-color-toggle-switch-default-checked-background-color-hover: var(--vis-color-gs-95);
	--vis-color-toggle-switch-default-checked-background-color-disabled: var(--vis-color-gs-90);
	--vis-color-toggle-switch-default-checked-background-color-focus: var(--vis-color-gs-90);
	--vis-color-toggle-switch-default-checked-background-color-active: var(--vis-color-gs-105);
	--vis-color-toggle-switch-default-checked-border-color: var(--vis-color-gs-120);
	--vis-color-toggle-switch-default-checked-border-color-disabled: var(--vis-color-gs-120);
	--vis-color-toggle-switch-primary-checked-background-color: var(--vis-color-pb-90);
	--vis-color-toggle-switch-primary-checked-background-color-disabled: var(--vis-color-pb-100);
	--vis-color-toggle-switch-primary-checked-background-color-hover: var(--vis-color-pb-90);
	--vis-color-toggle-switch-primary-checked-background-color-focus: var(--vis-color-pb-100);
	--vis-color-toggle-switch-primary-checked-background-color-active: var(--vis-color-pb-110);
}

@mixin color($theme) {
	$color-config: mat.m2-get-color-config($theme);

	.mat-mdc-slide-toggle.mat-accent {
		--mdc-switch-selected-focus-state-layer-color: transparent;
		--mdc-switch-selected-handle-color: var(--vis-color-white);
		--mdc-switch-selected-hover-state-layer-color: transparent;
		--mdc-switch-selected-pressed-state-layer-color: transparent;
		--mdc-switch-selected-focus-handle-color: var(--vis-color-white);
		--mdc-switch-selected-hover-handle-color: var(--vis-color-white);
		--mdc-switch-selected-pressed-handle-color: var(--vis-color-white);
		--mdc-switch-selected-focus-track-color: var(
			--vis-color-toggle-switch-primary-checked-background-color-focus
		);
		--mdc-switch-selected-hover-track-color: var(
			--vis-color-toggle-switch-primary-checked-background-color-hover
		);
		--mdc-switch-selected-pressed-track-color: var(
			--vis-color-toggle-switch-primary-checked-background-color
		);
		--mdc-switch-selected-track-color: var(
			--vis-color-toggle-switch-primary-checked-background-color
		);
		--mdc-switch-disabled-selected-track-color: var(
			--vis-color-toggle-switch-primary-checked-background-color
		);

		--mdc-slider-handle-color: var(--vis-color-white);
		--mdc-slider-focus-handle-color: var(--vis-color-white);
		--mdc-slider-hover-handle-color: var(--vis-color-white);
		--mdc-slider-active-track-color: var(--vis-color-interactive-element-bg-primary-enabled);
		--mdc-slider-inactive-track-color: var(--vis-color-interactive-element-bg-primary-enabled);
		--mdc-slider-with-tick-marks-inactive-container-color: transparent;
		--mdc-slider-with-tick-marks-active-container-color: transparent;
		--mat-mdc-slider-ripple-color: transparent;
		--mat-mdc-slider-hover-ripple-color: transparent;
		--mat-mdc-slider-focus-ripple-color: transparent;

		--mdc-circular-progress-active-indicator-color: var(
			--vis-color-interactive-element-bg-primary-enabled
		);

		.mdc-switch:enabled .mdc-switch__track::after {
			border-color: var(--vis-color-toggle-switch-primary-checked-border-color);
		}
	}

	.mat-mdc-slide-toggle,
	.mat-mdc-slide-toggle.mat-warn {
		--mdc-switch-selected-focus-state-layer-color: #404952;
		--mdc-switch-selected-handle-color: var(--vis-color-white);
		--mdc-switch-selected-hover-state-layer-color: #404952;
		--mdc-switch-selected-pressed-state-layer-color: #404952;
		--mdc-switch-selected-focus-handle-color: var(--vis-color-white);
		--mdc-switch-selected-hover-handle-color: var(--vis-color-white);
		--mdc-switch-selected-pressed-handle-color: var(--vis-color-white);
		--mdc-switch-selected-focus-track-color: var(
			--vis-color-toggle-switch-default-checked-background-color
		);
		--mdc-switch-selected-hover-track-color: var(
			--vis-color-toggle-switch-default-checked-background-color-hover
		);
		--mdc-switch-selected-pressed-track-color: var(
			--vis-color-toggle-switch-default-checked-background-color-active
		);
		--mdc-switch-selected-track-color: var(
			--vis-color-toggle-switch-default-checked-background-color
		);

		--mdc-switch-disabled-selected-handle-color: var(--vis-color-white);
		--mdc-switch-disabled-selected-icon-color: transparent;
		--mdc-switch-disabled-selected-track-color: var(
			--vis-color-toggle-switch-default-checked-background-color
		);
		--mdc-switch-disabled-unselected-handle-color: var(--vis-color-white);
		--mdc-switch-disabled-unselected-icon-color: transparent;
		--mdc-switch-disabled-unselected-track-color: var(
			--vis-color-toggle-switch-default-background-color-disabled
		);
		--mdc-switch-handle-surface-color: var(--vis-color-white);
		--mdc-switch-handle-elevation-shadow: 0;
		--mdc-switch-handle-shadow-color: none;
		--mdc-switch-disabled-handle-elevation-shadow: 0;

		--mdc-switch-selected-icon-color: var(--vis-color-white);
		--mdc-switch-unselected-focus-handle-color: var(--vis-color-white);
		--mdc-switch-unselected-focus-state-layer-color: transparent;
		--mdc-switch-unselected-focus-track-color: var(
			--vis-color-toggle-switch-default-background-color-focus
		);
		--mdc-switch-unselected-handle-color: var(--vis-color-white);
		--mdc-switch-unselected-hover-handle-color: var(--vis-color-white);
		--mdc-switch-unselected-hover-state-layer-color: transparent;
		--mdc-switch-unselected-hover-track-color: var(
			--vis-color-toggle-switch-default-background-color-hover
		);
		--mdc-switch-unselected-icon-color: transparent;
		--mdc-switch-unselected-pressed-handle-color: var(--vis-color-white);
		--mdc-switch-unselected-pressed-state-layer-color: transparent;
		--mdc-switch-unselected-pressed-track-color: var(
			--vis-color-toggle-switch-default-background-color-active
		);
		--mdc-switch-unselected-track-color: var(--vis-color-toggle-switch-default-background-color);

		--mat-mdc-slider-ripple-color: transparent;
		--mat-mdc-slider-hover-ripple-color: transparent;
		--mat-mdc-slider-focus-ripple-color: transparent;
	}

	.mat-mdc-slide-toggle.size-l .mdc-switch {
		--vis-toggle-switch-height: calc(var(--vis-gu) * 4);
		--vis-toggle-switch-width: calc(var(--vis-gu) * 7);
		--vis-toggle-switch-handle-size: calc(calc(var(--vis-gu) * 4) - calc(var(--vis-gu) / 1.8));
		--vis-toggle-switch-handle-margin: 3px;
	}

	.mdc-switch.mdc-switch {
		--vis-toggle-switch-height: calc(var(--vis-gu) * 2.5);
		--vis-toggle-switch-width: calc(var(--vis-gu) * 4.75);
		--vis-toggle-switch-handle-size: calc(var(--vis-gu) * 2);
		--vis-toggle-switch-handle-margin: 2px;

		--mdc-switch-disabled-handle-opacity: 1;
		--mdc-switch-disabled-selected-icon-opacity: 0;
		--mdc-switch-disabled-track-opacity: 1;
		--mdc-switch-disabled-unselected-icon-opacity: 0;
		--mdc-switch-handle-height: var(--vis-toggle-switch-handle-size);
		--mdc-switch-handle-shape: var(--vis-toggle-switch-handle-size);
		--mdc-switch-handle-width: var(--vis-toggle-switch-handle-size);
		--mat-switch-with-icon-handle-size: var(--vis-toggle-switch-handle-size);
		--mat-switch-disabled-selected-handle-opacity: 1;
		--mat-switch-disabled-unselected-handle-opacity: 1;
		--mat-switch-unselected-handle-size: var(--vis-toggle-switch-handle-size);
		--mat-switch-selected-handle-size: var(--vis-toggle-switch-handle-size);
		--mat-switch-pressed-handle-size: var(--vis-toggle-switch-handle-size);
		--mdc-switch-selected-icon-size: 0;
		--mdc-switch-track-height: var(--vis-toggle-switch-height);
		--mdc-switch-track-shape: var(--vis-toggle-switch-height);
		--mdc-switch-track-width: var(--vis-toggle-switch-width);
		--mdc-switch-unselected-icon-size: 0;
		--mdc-switch-state-layer-size: calc(var(--vis-gu) * 4);
		--mdc-switch-selected-focus-state-layer-opacity: 0;
		--mdc-switch-selected-hover-state-layer-opacity: 0;
		--mdc-switch-selected-pressed-state-layer-opacity: 0;
		--mdc-switch-unselected-focus-state-layer-opacity: 0;
		--mdc-switch-unselected-hover-state-layer-opacity: 0;
		--mdc-switch-unselected-pressed-state-layer-opacity: 0;
	}

	.mat-mdc-slide-toggle {
		.mdc-switch__icons,
		.mdc-switch__ripple,
		.mdc-switch__shadow {
			display: none !important;
		}

		.mdc-switch__track::before,
		.mdc-switch__track::after {
			border: 1px solid var(--vis-color-toggle-switch-default-border-color);
		}

		.mdc-switch__handle::before,
		.mdc-switch__handle::after {
			left: var(--vis-toggle-switch-handle-margin);
		}
	}

	.mdc-switch:enabled .mdc-switch__track::after {
		border-color: var(--vis-color-toggle-switch-default-checked-border-color);
	}

	.mdc-switch.mdc-switch--selected:enabled,
	.mdc-switch.mdc-switch--selected:disabled {
		.mdc-switch__handle::after,
		.mdc-switch__handle::before {
			left: calc(var(--vis-toggle-switch-handle-margin) * -1);
		}
	}

	.mdc-switch.mdc-switch--disabled {
		opacity: var(--vis-disabled-opacity);
	}
}

@mixin typography($theme) {
	.mat-mdc-slide-toggle .mdc-label {
		--mat-slide-toggle-label-text-font: var(--vis-font);
		--mat-slide-toggle-label-text-size: var(--vis-body-font-size);
		--mat-slide-toggle-label-text-tracking: 0;
		--mat-slide-toggle-label-text-line-height: var(--vis-body-line-height);

		color: var(--vis-color-text-default);
	}

	.mat-mdc-slide-toggle .mdc-switch--disabled + label {
		color: var(--vis-color-text-default);
		opacity: var(--vis-disabled-opacity);
	}
}

@mixin theme($theme) {
	$color-config: mat.m2-get-color-config($theme);

	@if $color-config != null {
		@include color($theme);
	}

	$typography-config: mat.m2-get-typography-config($theme);

	@if $typography-config != null {
		@include typography($theme);
	}
}
