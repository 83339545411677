@use 'sass:map';
@use '@angular/material' as mat;
@import '../material-utils';

@mixin color($theme) {
	$color-config: mat.m2-get-color-config($theme);
	$primary-palette: map.get($color-config, 'primary');
	$highlight-color: mat.m2-get-color-from-palette($primary-palette, 400);
	$highlight-color-hover: mat.m2-get-color-from-palette($primary-palette, 200);

	.mat-mdc-progress-spinner {
		--mdc-circular-progress-active-indicator-color: var(
			--vis-color-progress-ring-default-foreground
		);

		&.mat-primary {
			--mdc-circular-progress-active-indicator-color: var(
				--vis-color-progress-ring-highlight-foreground
			);
		}

		&.mat-accent {
			--mdc-circular-progress-active-indicator-color: var(
				--vis-color-progress-ring-primary-highlight-foreground
			);
		}
	}
}

@mixin typography($theme) {
}

@mixin theme($theme) {
	$color-config: mat.m2-get-color-config($theme);

	@if $color-config != null {
		@include color($theme);
	}

	$typography-config: mat.m2-get-typography-config($theme);

	@if $typography-config != null {
		@include typography($theme);
	}
}
