@use 'sass:map';
@use '@angular/material' as mat;
@import '../material-utils';

@mixin color($theme) {
	$color-config: mat.m2-get-color-config($theme);
	$primary-palette: map.get($color-config, 'primary');
	$primary-color: mat.m2-get-color-from-palette($primary-palette, 500);

	.mat-mdc-form-field {
		--mdc-filled-text-field-input-text-color: var(--vis-color-text-default);
		--mdc-filled-text-field-disabled-container-color: transparent;
		--mdc-filled-text-field-disabled-active-indicator-color: var(--vis-border-color);
		--mdc-filled-text-field-active-indicator-color: var(--vis-border-color);
		--mdc-filled-text-field-input-text-placeholder-color: var(--vis-color-placeholder-input);
		--mdc-filled-text-field-caret-color: var(--vis-color-text-default);
		--mdc-theme-text-primary-on-background: var(--vis-color-text-default);

		margin: 0;
		width: 100%;
		padding: 0;

		&.vis-hide-ripple-line {
			.mdc-line-ripple {
				display: none;
			}
		}

		&.disabled {
			pointer-events: none;
			opacity: var(--vis-disabled-opacity);

			.mat-mdc-form-field-icon-suffix,
			.mat-mdc-form-field-icon-prefix {
				pointer-events: none;
			}
		}

		&.mat-focused {
			--mdc-filled-text-field-input-text-placeholder-color: transparent;
		}

		&.readonly {
			.mdc-line-ripple {
				display: none;
			}

			.mat-mdc-form-field-icon-suffix,
			.mat-mdc-form-field-icon-prefix {
				opacity: var(--vis-disabled-opacity);
				pointer-events: none;
			}

			input {
				text-overflow: ellipsis;
				white-space: nowrap;
				overflow: hidden;
			}
		}

		.mdc-text-field__input::placeholder {
			opacity: 1;
		}

		&:hover {
			.mdc-text-field--filled:not(.mdc-text-field--disabled) {
				background: transparent !important;
			}
		}

		&.mat-form-field__textarea {
			--mdc-filled-text-field-container-shape: 0;

			&.readonly {
				textarea::placeholder {
					color: transparent;
				}

				&:hover .mat-mdc-form-field-infix {
					background: transparent;
				}

				.mat-mdc-form-field-infix {
					background: transparent;
				}
			}

			&.no-resize textarea {
				resize: none;
			}

			.mat-mdc-form-field-infix {
				padding: 0;
				background: var(--vis-color-second-hierarchy-bg-default-enabled);

				.mat-mdc-form-field-textarea-control {
					height: var(--vis-textarea-textarea-height);
				}
			}

			&:hover .mat-mdc-form-field-infix {
				background: var(--vis-color-second-hierarchy-bg-default-hovered);
			}
		}
	}

	.skip-readonly-color {
		.mat-mdc-form-field-icon-suffix,
		.mat-mdc-form-field-icon-prefix {
			--vis-disabled-opacity: 1;
		}
	}

	.hide-border .mat-mdc-form-field,
	.hide-border.mat-mdc-form-field {
		--mdc-filled-text-field-active-indicator-color: transparent;
		--mdc-filled-text-field-focus-active-indicator-color: transparent;
		--mdc-filled-text-field-hover-active-indicator-color: transparent;
		--vis-border-color-hover: transparent;
		--vis-border-color: transparent;
	}

	.mdc-text-field--filled {
		background: transparent !important;
	}

	.mat-mdc-input-element,
	.mat-mdc-form-field,
	.mdc-text-field {
		color: var(--vis-color-text-default);
	}

	.mat-mdc-form-field-focus-overlay {
		background: transparent !important;
	}

	// Sizes
	.mat-mdc-form-field-outline-start,
	.mat-mdc-form-field-outline-end,
	.mat-mdc-form-field-outline-gap {
		border-width: var(--vis-line-strength-thin) !important;
	}
	.mat-mdc-form-field-outline-start {
		border-radius: var(--vis-border-radius-default) 0 0 var(--vis-border-radius-default) !important;
	}
	.mat-mdc-form-field-outline-end {
		border-radius: 0 var(--vis-border-radius-default) var(--zui-border-radius-default) 0 !important;
	}

	// Set ripple color
	.mdc-text-field.mdc-text-field--filled:not(.mdc-text-field--disabled) {
		.mdc-line-ripple::before {
			border-bottom-color: var(--vis-border-color);
		}

		.mdc-line-ripple--active:before {
			border-bottom-width: 1px;
		}

		&:hover .mdc-line-ripple::before,
		.mdc-line-ripple--active:after {
			border-bottom-width: 2px;
			border-bottom-color: var(--vis-border-color-hover);
		}
	}

	// Density is nor enough, set smaller height
	div.mat-mdc-form-field-infix {
		min-height: calc(var(--vis-gu) * 3.75);
	}

	.input-time-picker div.mat-mdc-form-field-infix {
		width: auto;
	}

	@if isDarkTheme($theme) {
		.mdc-text-field--filled:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover
			.mdc-line-ripple::before {
			--mdc-filled-text-field-hover-active-indicator-color: var(--vis-border-color);
		}
	}
}

@mixin typography($theme) {
	$typography-config: mat.m2-get-typography-config($theme);
	$font-family: mat.m2-font-family($typography-config);

	.mat-mdc-form-field-label {
		font: var(--vis-typography-body);
	}
}

@mixin theme($theme) {
	$color-config: mat.m2-get-color-config($theme);

	@if $color-config != null {
		@include color($theme);
	}

	$typography-config: mat.m2-get-typography-config($theme);

	@if $typography-config != null {
		@include typography($theme);
	}
}
