:root {
	///////////////////////// vis COLORS ////////////////////////
	// TODO: AFTER IMPLEMENTATION RENAME
	--BK-rgb: 0, 0, 0;
	--BK: #000000;
	--vis-color-gs-120-rgb: 7, 9, 13;
	--vis-color-gs-120: #07090d;
	--vis-color-gs-115-rgb: 22, 29, 34;
	--vis-color-gs-115: #161d22;
	--vis-color-gs-110-rgb: 26, 35, 41;
	--vis-color-gs-110: #1a2329;
	--vis-color-gs-105-rgb: 38, 46, 54;
	--vis-color-gs-105: #262e36;
	--vis-color-gs-100-rgb: 53, 61, 69;
	--vis-color-gs-100: #353d45;
	--vis-color-gs-95-rgb: 64, 73, 82;
	--vis-color-gs-95: #404952;
	--vis-color-gs-90-rgb: 85, 94, 107;
	--vis-color-gs-90: #555e6b;
	--vis-color-gs-85-rgb: 98, 108, 122;
	--vis-color-gs-85: #626c7a;
	--vis-color-gs-80-rgb: 130, 141, 158;
	--vis-color-gs-80: #828d9e;
	--vis-color-gs-70-rgb: 165, 177, 194;
	--vis-color-gs-70: #a5b1c2;
	--vis-color-gs-60-rgb: 193, 203, 217;
	--vis-color-gs-60: #c1cbd9;
	--vis-color-gs-50-rgb: 209, 217, 229;
	--vis-color-gs-50: #d1d9e5;
	--vis-color-gs-40-rgb: 221, 227, 237;
	--vis-color-gs-40: #dde3ed;
	--vis-color-gs-35-rgb: 228, 235, 242;
	--vis-color-gs-35: #e4ebf2;
	--vis-color-gs-30-rgb: 237, 242, 247;
	--vis-color-gs-30: #edf2f7;
	--vis-color-gs-20-rgb: 245, 247, 250;
	--vis-color-gs-20: #f5f7fa;
	--vis-color-gs-10-rgb: 255, 255, 255;
	--vis-color-gs-10: #ffffff;
	--vis-color-pb-120-rgb: 0, 41, 175;
	--vis-color-pb-120: #0029af;
	--vis-color-pb-110-rgb: 0, 62, 205;
	--vis-color-pb-110: #003ecd;
	--vis-color-pb-100-rgb: 0, 80, 242;
	--vis-color-pb-100: #0050f2;
	--vis-color-pb-90-rgb: 46, 122, 245;
	--vis-color-pb-90: #2e7af5;
	--vis-color-pb-80-rgb: 92, 157, 255;
	--vis-color-pb-80: #5c9dff;
	--vis-color-pb-70-rgb: 133, 182, 255;
	--vis-color-pb-70: #85b6ff;
	--vis-color-pb-60-rgb: 166, 201, 255;
	--vis-color-pb-60: #a6c9ff;
	--vis-color-sc-r-110-rgb: 224, 71, 71;
	--vis-color-sc-r-110: #e04747;
	--vis-color-sc-r-100-rgb: 237, 83, 83;
	--vis-color-sc-r-100: #ed5353;
	--vis-color-sc-r-90-rgb: 252, 96, 96;
	--vis-color-sc-r-90: #fc6060;
	--vis-color-sc-p-110-rgb: 235, 67, 173;
	--vis-color-sc-p-110: #eb43ad;
	--vis-color-sc-p-100-rgb: 242, 81, 183;
	--vis-color-sc-p-100: #f251b7;
	--vis-color-sc-p-90-rgb: 255, 104, 199;
	--vis-color-sc-p-90: #ff68c7;
	--vis-color-sc-b-110-rgb: 0, 152, 230;
	--vis-color-sc-b-110: #0098e6;
	--vis-color-sc-b-100-rgb: 0, 166, 251;
	--vis-color-sc-b-100: #00a6fb;
	--vis-color-sc-b-90-rgb: 49, 185, 255;
	--vis-color-sc-b-90: #31b9ff;
	--vis-color-sc-g-110-rgb: 41, 193, 146;
	--vis-color-sc-g-110: #29c192;
	--vis-color-sc-g-100-rgb: 58, 210, 163;
	--vis-color-sc-g-100: #3ad2a3;
	--vis-color-sc-g-90-rgb: 78, 233, 185;
	--vis-color-sc-g-90: #4ee9b9;
	--vis-color-sc-y-110-rgb: 239, 185, 43;
	--vis-color-sc-y-110: #efb92b;
	--vis-color-sc-y-100-rgb: 253, 202, 64;
	--vis-color-sc-y-100: #fdca40;
	--vis-color-sc-y-90-rgb: 255, 213, 98;
	--vis-color-sc-y-90: #ffd562;
	--vis-color-sc-o-110-rgb: 233, 120, 71;
	--vis-color-sc-o-110: #e97847;
	--vis-color-sc-o-100-rgb: 239, 131, 84;
	--vis-color-sc-o-100: #ef8354;
	--vis-color-sc-o-90-rgb: 247, 137, 90;
	--vis-color-sc-o-90: #f7895a;
	--vis-color-ac-pu-110-rgb: 194, 45, 247;
	--vis-color-ac-pu-110: #c22df7;
	--vis-color-ac-pu-100-rgb: 202, 65, 252;
	--vis-color-ac-pu-100: #ca41fc;
	--vis-color-ac-pu-90-rgb: 209, 100, 248;
	--vis-color-ac-pu-90: #d164f8;
	--vis-color-ac-v-110-rgb: 107, 75, 235;
	--vis-color-ac-v-110: #6b4beb;
	--vis-color-ac-v-100-rgb: 119, 86, 251;
	--vis-color-ac-v-100: #7756fb;
	--vis-color-ac-v-90-rgb: 133, 102, 255;
	--vis-color-ac-v-90: #8566ff;
	--vis-color-ac-tu-110-rgb: 58, 199, 206;
	--vis-color-ac-tu-110: #3ac7ce;
	--vis-color-ac-tu-100-rgb: 69, 215, 222;
	--vis-color-ac-tu-100: #45d7de;
	--vis-color-ac-tu-90-rgb: 88, 222, 228;
	--vis-color-ac-tu-90: #58dee4;
	--vis-color-ac-db-110-rgb: 115, 143, 186;
	--vis-color-ac-db-110: #738fba;
	--vis-color-ac-db-100-rgb: 133, 161, 204;
	--vis-color-ac-db-100: #85a1cc;
	--vis-color-ac-db-90-rgb: 154, 179, 219;
	--vis-color-ac-db-90: #9ab3db;
	--vis-color-ac-cg-110-rgb: 48, 187, 76;
	--vis-color-ac-cg-110: #30bb4c;
	--vis-color-ac-cg-100-rgb: 56, 205, 86;
	--vis-color-ac-cg-100: #38cd56;
	--vis-color-ac-cg-90-rgb: 72, 217, 101;
	--vis-color-ac-cg-90: #48d965;
	--vis-color-ac-l-110-rgb: 158, 217, 41;
	--vis-color-ac-l-110: #9ed929;
	--vis-color-ac-l-100-rgb: 168, 228, 49;
	--vis-color-ac-l-100: #a8e431;
	--vis-color-ac-l-90-rgb: 180, 242, 59;
	--vis-color-ac-l-90: #b4f23b;
	--vis-color-ac-br-110-rgb: 162, 123, 84;
	--vis-color-ac-br-110: #a27b54;
	--vis-color-ac-br-100-rgb: 183, 141, 101;
	--vis-color-ac-br-100: #b78d65;
	--vis-color-ac-br-90-rgb: 195, 156, 119;
	--vis-color-ac-br-90: #c39c77;
}

// Code font style
:root {
	--vis-code-font-style: normal;
	--vis-code-font-weight: normal;
	--vis-code-font-size: 12px;
	--vis-code-font-line-height: 1.2;
	--vis-typography-code: var(--vis-code-font-style) var(--vis-code-font-weight)
		var(--vis-code-font-size) / var(--vis-code-font-line-height) monospace;
}

// Fonts
:root {
	--vis-font-family: 'Noto Sans', 'Segoe UI', sans-serif;
	--vis-h1-font-style: normal;
	--vis-h1-font-weight: 300;
	--vis-h1-font-size: 46px;
	--vis-h1-line-height: 1.56521739;
	--vis-h2-font-style: normal;
	--vis-h2-font-weight: 300;
	--vis-h2-font-size: 31px;
	--vis-h2-line-height: 1.5483871;
	--vis-h3-font-style: normal;
	--vis-h3-font-weight: 300;
	--vis-h3-font-size: 22px;
	--vis-h3-line-height: 1.63636364;
	--vis-h4-font-style: normal;
	--vis-h4-font-weight: 300;
	--vis-h4-font-size: 19px;
	--vis-h4-line-height: 1.47368421;
	--vis-h5-font-style: normal;
	--vis-h5-font-weight: 400;
	--vis-h5-font-size: 15px;
	--vis-h5-line-height: 1.6;
	--vis-subtitle1-font-style: normal;
	--vis-subtitle1-font-weight: 600;
	--vis-subtitle1-font-size: 13px;
	--vis-subtitle1-line-height: 1.53846154;
	--vis-subtitle2-font-style: normal;
	--vis-subtitle2-font-weight: 700;
	--vis-subtitle2-font-size: 11px;
	--vis-subtitle2-line-height: 1.45454545;
	--vis-body-font-style: normal;
	--vis-body-font-weight: 400;
	--vis-body-font-size: 13px;
	--vis-body-line-height: 1.53846154;
	--vis-caption-font-style: normal;
	--vis-caption-font-weight: 400;
	--vis-caption-font-size: 11px;
	--vis-caption-line-height: 1.45454545;
	--vis-label1-font-style: normal;
	--vis-label1-font-weight: 600;
	--vis-label1-font-size: 11px;
	--vis-label1-line-height: 1.45454545;
	--vis-label2-font-style: normal;
	--vis-label2-font-weight: 700;
	--vis-label2-font-size: 10px;
	--vis-label2-line-height: 1.2;
	--vis-table-font-size: 12px;
}

:root {
	--vis-typography-body: var(--vis-body-font-style) var(--vis-body-font-weight)
			var(--vis-body-font-size) / var(--vis-body-line-height) var(--vis-font-family),
		sans-serif;
	--vis-typography-h0: var(--vis-h0-font-style) var(--vis-h0-font-weight) var(--vis-h0-font-size) /
			var(--vis-h0-line-height) var(--vis-font-family),
		sans-serif;
	--vis-typography-h1: var(--vis-h1-font-style) var(--vis-h1-font-weight) var(--vis-h1-font-size) /
			var(--vis-h1-line-height) var(--vis-font-family),
		sans-serif;
	--vis-typography-h2: var(--vis-h2-font-style) var(--vis-h2-font-weight) var(--vis-h2-font-size) /
			var(--vis-h2-line-height) var(--vis-font-family),
		sans-serif;
	--vis-typography-h3: var(--vis-h3-font-style) var(--vis-h3-font-weight) var(--vis-h3-font-size) /
			var(--vis-h3-line-height) var(--vis-font-family),
		sans-serif;
	--vis-typography-h4: var(--vis-h4-font-style) var(--vis-h4-font-weight) var(--vis-h4-font-size) /
			var(--vis-h4-line-height) var(--vis-font-family),
		sans-serif;
	--vis-typography-subtitle1: var(--vis-subtitle1-font-style) var(--vis-subtitle1-font-weight)
			var(--vis-subtitle1-font-size) / var(--vis-subtitle1-line-height) var(--vis-font-family),
		sans-serif;
	--vis-typography-subtitle2: var(--vis-subtitle2-font-style) var(--vis-subtitle2-font-weight)
			var(--vis-subtitle2-font-size) / var(--vis-subtitle2-line-height) var(--vis-font-family),
		sans-serif;
	--vis-typography-caption: var(--vis-caption-font-style) var(--vis-caption-font-weight)
			var(--vis-caption-font-size) / var(--vis-caption-line-height) var(--vis-font-family),
		sans-serif;
	--vis-typography-label1: var(--vis-label1-font-style) var(--vis-label1-font-weight)
			var(--vis-label1-font-size) / var(--vis-label1-line-height) var(--vis-font-family),
		sans-serif;
	--vis-typography-label2: var(--vis-label2-font-style) var(--vis-label2-font-weight)
			var(--vis-label2-font-size) / var(--vis-label2-line-height) var(--vis-font-family),
		sans-serif;
	--vis-typography-label2-text-transform: uppercase;
}

// ZUI Typography enforcement
:root {
	--zui-typography-body: var(--zui-body-font-style) var(--zui-body-font-weight)
		var(--zui-body-font-size) / var(--zui-body-line-height) var(--zui-font-family);

	--zui-typography-h0: var(--zui-h0-font-style) var(--zui-h0-font-weight) var(--zui-h0-font-size) /
		var(--zui-h0-line-height) var(--zui-font-family);

	--zui-typography-h1: var(--zui-h1-font-style) var(--zui-h1-font-weight) var(--zui-h1-font-size) /
		var(--zui-h1-line-height) var(--zui-font-family);

	--zui-typography-h2: var(--zui-h2-font-style) var(--zui-h2-font-weight) var(--zui-h2-font-size) /
		var(--zui-h2-line-height) var(--zui-font-family);

	--zui-typography-h3: var(--zui-h3-font-style) var(--zui-h3-font-weight) var(--zui-h3-font-size) /
		var(--zui-h3-line-height) var(--zui-font-family);

	--zui-typography-h4: var(--zui-h4-font-style) var(--zui-h4-font-weight) var(--zui-h4-font-size) /
		var(--zui-h4-line-height) var(--zui-font-family);

	--zui-typography-subtitle1: var(--zui-subtitle1-font-style) var(--zui-subtitle1-font-weight)
		var(--zui-subtitle1-font-size) / var(--zui-subtitle1-line-height) var(--zui-font-family);

	--zui-typography-subtitle2: var(--zui-subtitle2-font-style) var(--zui-subtitle2-font-weight)
		var(--zui-subtitle2-font-size) / var(--zui-subtitle2-line-height) var(--zui-font-family);

	--zui-typography-caption: var(--zui-caption-font-style) var(--zui-caption-font-weight)
		var(--zui-caption-font-size) / var(--zui-caption-line-height) var(--zui-font-family);

	--zui-typography-label1: var(--zui-label1-font-style) var(--zui-label1-font-weight)
		var(--zui-label1-font-size) / var(--zui-label1-line-height) var(--zui-font-family);

	--zui-typography-label2: var(--zui-label2-font-style) var(--zui-label2-font-weight)
		var(--zui-label2-font-size) / var(--zui-label2-line-height) var(--zui-font-family);
}

@font-face {
	font-family: 'Noto Sans';
	src: url('/fonts/NotoSans-Light.woff2') format('woff2'),
		url('/fonts/NotoSans-Light.woff') format('woff');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Noto Sans';
	src: url('/fonts/NotoSans-Regular.woff2') format('woff2'),
		url('/fonts/NotoSans-Regular.woff') format('woff');
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Noto Sans';
	src: url('/fonts/NotoSans-SemiBold.woff2') format('woff2'),
		url('/fonts/NotoSans-SemiBold.woff') format('woff');
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Noto Sans';
	src: url('/fonts/NotoSans-Bold.woff2') format('woff2'),
		url('/fonts/NotoSans-Bold.woff') format('woff');
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Noto Sans';
	src: url('/fonts/NotoSans-LightItalic.woff2') format('woff2'),
		url('/fonts/NotoSans-LightItalic.woff') format('woff');
	font-weight: 300;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Noto Sans';
	src: url('/fonts/NotoSans-Italic.woff2') format('woff2'),
		url('/fonts/NotoSans-Italic.woff') format('woff');
	font-weight: 400;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Noto Sans';
	src: url('/fonts/NotoSans-SemiBoldItalic.woff2') format('woff2'),
		url('/fonts/NotoSans-SemiBoldItalic.woff') format('woff');
	font-weight: 600;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Noto Sans';
	src: url('/fonts/NotoSans-BoldItalic.woff2') format('woff2'),
		url('/fonts/NotoSans-BoldItalic.woff') format('woff');
	font-weight: 700;
	font-style: italic;
	font-display: swap;
}

/*
* Menubar
*/
:root {
	--zui-menubar-nav-item-height: calc(var(--vis-gu) * 6.75);
	--zui-menubar-default-width: 64px;

	--vis-menubar-nav-item-height: calc(var(--vis-gu) * 6.75);
	--vis-menubar-default-width: 64px;

	[media='mobile'] {
		--zui-menubar-nav-item-height: calc(var(--vis-gu) * 5);
		--zui-menubar-width: calc(var(--vis-gu) * 5);

		--vis-menubar-nav-item-height: calc(var(--vis-gu) * 5);
		--vis-menubar-width: calc(var(--vis-gu) * 5);
	}
}

/*
* Icon sizes
*/
:root {
	// TODO: workaround for zui 1.25.5 as they somehow removed the following variables
	--zui-size-icon-extra-small: var(--vis-gu);
	--zui-size-icon-small: calc(var(--vis-gu) * 1.5);
	--zui-size-icon-medium: calc(var(--vis-gu) * 2);
	--zui-size-icon-large: calc(var(--vis-gu) * 3);
	--zui-size-icon-extra-large: calc(var(--vis-gu) * 4);
	--zui-size-icon-2xl: calc(var(--vis-gu) * 6);
	--zui-size-icon-3xl: calc(var(--vis-gu) * 8);
	--zui-size-icon-4xl: calc(var(--vis-gu) * 10);

	--vis-size-icon-extra-small: var(--vis-gu);
	--vis-size-icon-small: calc(var(--vis-gu) * 1.5);
	--vis-size-icon-medium: calc(var(--vis-gu) * 2);
	--vis-size-icon-large: calc(var(--vis-gu) * 3);
	--vis-size-icon-extra-large: calc(var(--vis-gu) * 4);
	--vis-size-icon-2xl: calc(var(--vis-gu) * 6);
	--vis-size-icon-3xl: calc(var(--vis-gu) * 8);
	--vis-size-icon-4xl: calc(var(--vis-gu) * 10);
}

/*
* Colors
*/
:root {
	--vis-color-error: var(--vis-color-sc-r-100);
	--vis-color-warning: var(--vis-color-sc-y-100);
	--vis-color-success: var(--vis-color-sc-g-110);
	--vis-color-interactive: var(--vis-color-pb-90);
	--vis-color-interactive-hover: var(--vis-color-pb-100);
	--vis-color-accent: #008bd0;
	--vis-color-purple: #9370db;
	--vis-color-turquoise: #48d1cc;
	--vis-color-pink: #ff69b4;
	--vis-color-indigo: #0f2db3;
	--vis-color-brown: var(--vis-color-ac-br-110);
	--vis-color-orange: var(--vis-color-sc-o-100);
	--vis-color-white: #fff;
	--vis-color-black: #000;
	--vis-color-ultra-lightgray: var(--vis-color-gs-20);
	--vis-color-semiwhite-gray: var(--vis-color-gs-30);
	--vis-color-lightgray: var(--vis-color-gs-40);
	--vis-color-midgray: var(--vis-color-gs-60);
	--vis-color-gray: #778592;
	--vis-color-semidark: #606a76;
	--vis-color-darkgray: #4e565f;
	--vis-color-ultradark-gray: #32373e;

	// TODO: overwrite wrong zui-color variables
	// there are more to overwrite:
	--zui-color-pb-100: var(--vis-color-interactive) !important;
}

:root {
	--vis-gu: 8px;

	/* Spacing */
	--vis-space-extra-small: calc(var(--vis-gu) * 0.75);
	--vis-space-small: var(--vis-gu);
	--vis-space-medium: calc(var(--vis-gu) * 2);
	--vis-space-large: calc(var(--vis-gu) * 4);

	/* Disabled */
	--vis-disabled-opacity: 0.5;
	--vis-selection-opacity: 0.3;

	/* Line and Border Strengths */
	--vis-line-strength-thin: 1px;
	--vis-line-strength-medium: calc(var(--vis-gu) / 4);
	--vis-line-strength-thick: calc(var(--vis-gu) / 2);

	/* Border Radius */
	--vis-border-radius-default: 3px;

	/* Shadows */
	--vis-color-box-shadow-default: rgba(0, 0, 0, 0.2);
	--vis-box-shadow-default: 0px 3px 8px var(--vis-color-box-shadow-default);
	--vis-box-shadow-large: 0px 3px 12px var(--vis-color-box-shadow-default);
	--vis-box-shadow-large-inverted: 0px -3px 12px var(--vis-color-box-shadow-default);

	/* Button */
	--vis-button-default-width: 120px;
	--vis-button-max-width: 250px;
	--vis-button-min-width: 100px;
	--vis-button-width: 100%;
	--vis-button-horizontal-padding: var(--vis-gu);

	/* Checkbox */
	--vis-checkbox-size: calc(var(--vis-gu) * 2);

	// Searchbar
	--vis-color-searchbar-input-background: var(--vis-color-gs-30);
}

:root,
*:not(html)[theme='light'] {
	/* Background Colors */
	--vis-color-app-bg: var(--vis-color-white);
	--vis-color-portal-bg: var(--vis-color-white);
	--box-shadow-large: 0px 3px 12px rgba(var(--BK-rgb), 0.2);
	// Not in style guide, needs to be checked
	--vis-color-interactive-element-bg-default-enabled: var(--vis-color-semiwhite-gray);
	--vis-color-interactive-element-bg-default-disabled: var(--vis-color-semiwhite-gray);
	--vis-color-interactive-element-bg-default-hovered: var(--vis-color-lightgray);
	--vis-color-interactive-element-bg-default-pressed: var(--vis-color-gs-50);
	--vis-color-interactive-element-bg-activated-hovered: var(--vis-color-gs-70);
	--vis-color-interactive-element-bg-activated-enabled: var(--vis-color-gs-80);
	--vis-color-interactive-element-bg-activated-pressed: var(--vis-color-gs-90);

	--vis-border-color: var(--vis-color-midgray);
	--vis-border-color-modal: var(--vis-color-midgray);
	--vis-border-color-hover: var(--vis-color-gs-90);

	--vis-color-text-default: var(--vis-color-ultradark-gray);
	--vis-color-text-on-dark-bg: var(--vis-color-white);
	--vis-color-icon-interactive-bg-enabled: var(--vis-color-semidark);
	--vis-color-placeholder-input: var(--vis-color-gs-80);
	--vis-color-interactive-element-thin-line-activated-enabled: var(--vis-color-gs-90);
	--vis-color-interactive-element-bg-primary-enabled: var(--vis-color-pb-90);
	--vis-color-interactive-element-thin-line-primary-enabled: var(--vis-color-pb-120);

	--vis-color-thin-line-highlight: var(--vis-color-gs-60);

	// Select
	--vis-color-bg-select: var(--vis-color-gs-20);
	--vis-color-bg-select-hover: var(--vis-color-gs-30);
	--vis-color-bg-select-active: var(--vis-color-gs-40);
	--vis-color-bg-pseudo-checkbox: var(--vis-color-gs-35);
	--vis-color-bg-pseudo-checkbox-checked: var(--vis-color-gray);
	--vis-color-border-pseudo-checkbox-checked: var(--vis-color-gs-90);

	--vis-color-select-bottom-line-selected-enabled: var(--vis-color-gs-120);
	--vis-color-select-bottom-line-selected-hovered: var(--vis-color-gs-120);
	--vis-color-select-bottom-line-selected-focused: var(--vis-color-gs-120);
	--vis-color-select-bottom-line-selected-pressed: var(--vis-color-gs-120);
	--vis-color-select-bottom-line-opened-enabled: var(--vis-color-gs-120);
	--vis-color-select-bottom-line-opened-hovered: var(--vis-color-gs-120);
	--vis-color-select-bottom-line-opened-focused: var(--vis-color-gs-120);
	--vis-color-select-bottom-line-opened-pressed: var(--vis-color-gs-120);

	// Backgrounds
	--vis-color-first-hierarchy-bg-default-enabled: var(--vis-color-gs-10);
	--vis-color-first-hierarchy-bg-default-hovered: var(--vis-color-gs-20);
	--vis-color-first-hierarchy-bg-default-pressed: var(--vis-color-gs-30);
	--vis-color-first-hierarchy-bg-active-enabled: var(--vis-color-gs-30);
	--vis-color-first-hierarchy-bg-active-hovered: var(--vis-color-gs-40);
	--vis-color-first-hierarchy-bg-active-pressed: var(--vis-color-gs-50);
	--vis-color-first-hierarchy-bg-active-primary-enabled: var(--vis-color-pb-100);
	--vis-color-first-hierarchy-bg-active-primary-hovered: var(--vis-color-pb-90);
	--vis-color-first-hierarchy-bg-active-primary-pressed: var(--vis-color-pb-110);

	--vis-color-second-hierarchy-bg-default-enabled: var(--vis-color-gs-20);
	--vis-color-second-hierarchy-bg-default-hovered: var(--vis-color-gs-30);
	--vis-color-second-hierarchy-bg-default-pressed: var(--vis-color-gs-40);
	--vis-color-second-hierarchy-bg-active-enabled: var(--vis-color-gs-40);
	--vis-color-second-hierarchy-bg-active-hovered: var(--vis-color-gs-50);
	--vis-color-second-hierarchy-bg-active-pressed: var(--vis-color-gs-60);
	--vis-color-second-hierarchy-bg-primary-enabled: var(--vis-color-pb-100);
	--vis-color-second-hierarchy-bg-primary-hovered: var(--vis-color-pb-110);
	--vis-color-second-hierarchy-bg-primary-pressed: var(--vis-color-pb-120);

	--vis-color-third-hierarchy-bg-default-enabled: var(--vis-color-gs-30);
	--vis-color-third-hierarchy-bg-default-hovered: var(--vis-color-gs-40);
	--vis-color-third-hierarchy-bg-default-pressed: var(--vis-color-gs-50);
	--vis-color-third-hierarchy-bg-default-primary-enabled: var(--vis-color-gs-105);
	--vis-color-third-hierarchy-bg-default-primary-hovered: var(--vis-color-gs-115);
	--vis-color-third-hierarchy-bg-default-primary-pressed: var(--vis-color-gs-120);
	--vis-color-third-hierarchy-bg-active-enabled: var(--vis-color-gs-40);
	--vis-color-third-hierarchy-bg-active-hovered: var(--vis-color-gs-50);
	--vis-color-third-hierarchy-bg-active-pressed: var(--vis-color-gs-60);

	--vis-color-fourth-hierarchy-bg-default-enabled: var(--vis-color-gs-40);
	--vis-color-fourth-hierarchy-bg-default-hovered: var(--vis-color-gs-50);
	--vis-color-fourth-hierarchy-bg-default-pressed: var(--vis-color-gs-60);
	--vis-color-text-box-bg: var(--vis-color-gs-30);

	--vis-color-text-divider: var(--vis-color-gs-85);

	--vis-textarea-textarea-height: 84px;

	// interactive text
	--vis-color-interactive-text-default-enabled: var(--vis-color-gs-100);
	--vis-color-interactive-text-default-hovered: var(--vis-color-gs-70);
	--vis-color-interactive-text-default-focused: var(--vis-color-gs-100);
	--vis-color-interactive-text-default-pressed: var(--vis-color-gs-85);

	--vis-color-interactive-text-subtle-enabled: var(--vis-color-gs-85);
	--vis-color-interactive-text-subtle-hovered: var(--vis-color-gs-60);
	--vis-color-interactive-text-subtle-focused: var(--vis-color-gs-85);
	--vis-color-interactive-text-subtle-pressed: var(--vis-color-gs-70);

	--vis-color-interactive-text-highlight-enabled: var(--vis-color-gs-120);
	--vis-color-interactive-text-highlight-hovered: var(--vis-color-gs-80);
	--vis-color-interactive-text-highlight-focused: var(--vis-color-gs-120);
	--vis-color-interactive-text-highlight-pressed: var(--vis-color-gs-85);

	--vis-color-interactive-text-primary-highlight-enabled: var(--vis-color-pb-100);
	--vis-color-interactive-text-primary-highlight-hovered: var(--vis-color-pb-80);
	--vis-color-interactive-text-primary-highlight-focused: var(--vis-color-pb-100);
	--vis-color-interactive-text-primary-highlight-pressed: var(--vis-color-pb-90);

	// Tooltip
	--vis-tooltip-border-color: var(--vis-color-gs-60);

	// Time picker
	--vis-time-picker-cell-divider-color: var(--vis-color-gs-100);
	--vis-time-picker-cell-background-color: var(--vis-color-gs-30);
	--vis-time-picker-cell-background-color-hovered: var(--vis-color-gs-40);
	--vis-time-picker-cell-background-color-focused: var(--vis-color-gs-30);
	--vis-time-picker-cell-background-color-pressed: var(--vis-color-gs-50);
	--vis-time-picker-cell-border-color: var(--vis-color-gs-60);
	--vis-time-picker-cell-border-color-hovered: var(--vis-color-gs-60);
	--vis-time-picker-cell-border-color-focused: var(--vis-color-gs-60);
	--vis-time-picker-cell-border-color-pressed: var(--vis-color-gs-60);
	--vis-time-picker-cell-text-color: var(--vis-color-gs-120);
	--vis-time-picker-cell-text-color-hovered: var(--vis-color-gs-120);
	--vis-time-picker-cell-text-color-focused: var(--vis-color-gs-120);
	--vis-time-picker-cell-text-color-pressed: var(--vis-color-gs-120);
	--vis-time-picker-input-border-bottom-disabled-color: rgba(
		var(--vis-color-gs-60-rgb),
		var(--vis-disabled-opacity)
	);

	// Headerbar
	--vis-color-headerbar-button-divider-border-color: var(--vis-color-gs-40);
}

html[theme='dark'] {
	/* Background Colors */
	--vis-color-app-bg: var(--vis-color-ultradark-gray);
	--box-shadow-large: 0px 3px 12px rgba(var(--BK-rgb), 0.4);
	// Not in style guide, needs to be checked
	--vis-color-interactive-element-bg-default-enabled: var(--vis-color-gs-110);
	--vis-color-interactive-element-bg-default-disabled: var(--vis-color-gs-110);
	--vis-color-interactive-element-bg-default-hovered: var(--vis-color-gs-115);
	--vis-color-interactive-element-bg-default-pressed: var(--vis-color-gs-120);
	--vis-color-icon-interactive-bg-enabled: var(--vis-color-midgray);
	--vis-color-interactive-element-bg-activated-hovered: var(--vis-color-gs-95);
	--vis-color-interactive-element-bg-activated-enabled: var(--vis-color-gs-90);
	--vis-color-interactive-element-bg-activated-pressed: var(--vis-color-gs-105);

	--vis-border-color: var(--vis-color-gs-120);
	--vis-border-color-modal: var(--vis-color-gs-115);

	--vis-color-thin-line-highlight: var(--vis-color-gs-120);

	// Select
	--vis-color-bg-select: var(--vis-color-gs-105);
	--vis-color-bg-select-hover: var(--vis-color-gs-110);
	--vis-color-bg-select-active: var(--vis-color-gs-115);
	--vis-color-bg-pseudo-checkbox: var(--vis-color-gs-110);
	--vis-color-bg-pseudo-checkbox-checked: var(--vis-color-gs-90);
	--vis-color-select-bottom-line-selected-enabled: var(--vis-color-gs-60);
	--vis-color-select-bottom-line-selected-hovered: var(--vis-color-gs-120);
	--vis-color-select-bottom-line-selected-focused: var(--vis-color-gs-60);
	--vis-color-select-bottom-line-selected-pressed: var(--vis-color-gs-100);
	--vis-color-select-bottom-line-opened-enabled: var(--vis-color-gs-120);
	--vis-color-select-bottom-line-opened-hovered: var(--vis-color-gs-120);
	--vis-color-select-bottom-line-opened-focused: var(--vis-color-gs-120);
	--vis-color-select-bottom-line-opened-pressed: var(--vis-color-gs-120);

	// used for text fields
	--vis-border-color-hover: var(--vis-color-gs-120);
	--vis-color-text-default: var(--vis-color-lightgray);
	--vis-color-placeholder-input: var(--vis-color-gs-80);
	--vis-color-interactive-element-thin-line-activated-enabled: var(--vis-color-gs-120);

	// Backgrounds
	--vis-color-first-hierarchy-bg-default-enabled: var(--vis-color-gs-100);
	--vis-color-first-hierarchy-bg-default-hovered: var(--vis-color-gs-105);
	--vis-color-first-hierarchy-bg-default-pressed: var(--vis-color-gs-110);
	--vis-color-first-hierarchy-bg-active-enabled: var(--vis-color-gs-110);
	--vis-color-first-hierarchy-bg-active-hovered: var(--vis-color-gs-115);
	--vis-color-first-hierarchy-bg-active-pressed: var(--vis-color-gs-120);
	--vis-color-first-hierarchy-bg-active-primary-enabled: var(--vis-color-pb-100);
	--vis-color-first-hierarchy-bg-active-primary-hovered: var(--vis-color-pb-90);
	--vis-color-first-hierarchy-bg-active-primary-pressed: var(--vis-color-pb-110);

	--vis-color-second-hierarchy-bg-default-enabled: var(--vis-color-gs-105);
	--vis-color-second-hierarchy-bg-default-hovered: var(--vis-color-gs-110);
	--vis-color-second-hierarchy-bg-default-pressed: var(--vis-color-gs-115);
	--vis-color-second-hierarchy-bg-active-enabled: var(--vis-color-gs-115);
	--vis-color-second-hierarchy-bg-active-hovered: var(--vis-color-gs-120);
	--vis-color-second-hierarchy-bg-active-pressed: var(--vis-color-gs-120);
	--vis-color-second-hierarchy-bg-primary-enabled: var(--vis-color-pb-100);
	--vis-color-second-hierarchy-bg-primary-hovered: var(--vis-color-pb-90);
	--vis-color-second-hierarchy-bg-primary-pressed: var(--vis-color-pb-110);

	--vis-color-third-hierarchy-bg-default-enabled: var(--vis-color-gs-110);
	--vis-color-third-hierarchy-bg-default-hovered: var(--vis-color-gs-115);
	--vis-color-third-hierarchy-bg-default-pressed: var(--vis-color-gs-120);
	--vis-color-third-hierarchy-bg-default-primary-enabled: var(--vis-color-gs-110);
	--vis-color-third-hierarchy-bg-default-primary-hovered: var(--vis-color-gs-115);
	--vis-color-third-hierarchy-bg-default-primary-pressed: var(--vis-color-gs-120);
	--vis-color-third-hierarchy-bg-active-enabled: var(--vis-color-gs-120);
	--vis-color-third-hierarchy-bg-active-hovered: var(--vis-color-gs-120);
	--vis-color-third-hierarchy-bg-active-pressed: var(--vis-color-gs-120);

	--vis-color-fourth-hierarchy-bg-default-enabled: var(--vis-color-gs-115);
	--vis-color-fourth-hierarchy-bg-default-hovered: var(--vis-color-gs-120);
	--vis-color-fourth-hierarchy-bg-default-pressed: var(--vis-color-gs-120);
	--vis-color-text-box-bg: var(--vis-color-gs-110);

	--vis-color-text-divider: var(--vis-color-gs-70);

	//interactive
	--vis-color-interactive-text-default-enabled: var(--vis-color-gs-50);
	--vis-color-interactive-text-default-hovered: var(--vis-color-gs-80);
	--vis-color-interactive-text-default-focused: var(--vis-color-gs-50);
	--vis-color-interactive-text-default-pressed: var(--vis-color-gs-70);

	--vis-color-interactive-text-subtle-enabled: var(--vis-color-gs-70);
	--vis-color-interactive-text-subtle-hovered: var(--vis-color-gs-90);
	--vis-color-interactive-text-subtle-focused: var(--vis-color-gs-70);
	--vis-color-interactive-text-subtle-pressed: var(--vis-color-gs-85);

	--vis-color-interactive-text-highlight-enabled: var(--vis-color-gs-10);
	--vis-color-interactive-text-highlight-hovered: var(--vis-color-gs-70);
	--vis-color-interactive-text-highlight-focused: var(--vis-color-gs-10);
	--vis-color-interactive-text-highlight-pressed: var(--vis-color-gs-60);

	--vis-color-interactive-text-primary-highlight-enabled: var(--vis-color-pb-90);
	--vis-color-interactive-text-primary-highlight-hovered: var(--vis-color-pb-70);
	--vis-color-interactive-text-primary-highlight-focused: var(--vis-color-pb-90);
	--vis-color-interactive-text-primary-highlight-pressed: var(--vis-color-pb-80);

	// Tooltip
	--vis-tooltip-border-color: var(--vis-color-gs-120);

	// Time picker
	--vis-time-picker-cell-background-color: var(--vis-color-gs-110);
	--vis-time-picker-cell-background-color-hovered: var(--vis-color-gs-115);
	--vis-time-picker-cell-background-color-focused: var(--vis-color-gs-110);
	--vis-time-picker-cell-background-color-pressed: var(--vis-color-gs-120);

	--vis-time-picker-cell-border-color: var(--vis-color-gs-120);

	--vis-time-picker-cell-text-color: var(--vis-color-gs-10);

	--vis-time-picker-cell-divider-color: var(--vis-color-gs-50);

	--vis-time-picker-input-border-bottom-disabled-color: rgba(
		var(--vis-color-gs-120-rgb),
		var(--vis-disabled-opacity)
	);

	// Headerbar
	--vis-color-headerbar-button-divider-border-color: var(--vis-color-gs-120);

	// Searchbar
	--vis-color-searchbar-input-background: var(--vis-color-gs-115);
}

// Mat tabs
:root {
	--vis-color-menu-tab-item-default-background-active: var(--vis-color-gs-30);
	--vis-color-menu-tab-item-default-background-focus: var(--vis-color-gs-10);
	--vis-color-menu-tab-item-default-background-hover: var(--vis-color-gs-20);
	--vis-color-menu-tab-item-default-background: var(--vis-color-gs-10);
	--vis-color-menu-tab-item-selected-background: var(--vis-color-gs-10);

	--vis-color-menu-tab-item-default: var(--vis-color-gs-100);
	--vis-color-menu-tab-item-selected-border-bottom: var(--vis-color-pb-100);
	--vis-color-menu-tab-item-selected: var(--vis-color-gs-120);

	/* State */
	--vis-color-state-label-background-color-default: var(--vis-color-gs-35);
	--vis-color-state-label-background-color-error: rgba(var(--vis-color-sc-r-100-rgb), 0.2);
	--vis-color-state-label-background-color-progress: rgba(var(--vis-color-pb-100-rgb), 0.2);
	--vis-color-state-label-background-color-ready: rgba(var(--vis-color-sc-g-110-rgb), 0.2);
	--vis-color-state-label-background-color-warning: rgba(var(--vis-color-sc-y-100-rgb), 0.2);
	--vis-color-state-label-text-color-default: var(--vis-color-gs-100);
	--vis-color-state-label-text-color-error: var(--vis-color-sc-r-100);
	--vis-color-state-label-text-color-progress: var(--vis-color-pb-100);
	--vis-color-state-label-text-color-ready: var(--vis-color-sc-g-110);
	--vis-color-state-label-text-color-warning: var(--vis-color-sc-y-110);

	/* ewi dialog */
	--vis-color-dialog-button-default: var(--vis-color-gs-120);
	--vis-color-text-secondary-line: var(--vis-color-gs-85);
	--vis-ewiq-button-alignment: column;
	--vis-color-dialog-box-separator-line: var(--vis-color-gs-40);
	--vis-ewiq-button-border-top: 1px solid var(--vis-color-dialog-box-separator-line);
	--vis-color-ewiq-dialog-header-text-color: var(--vis-color-gs-120);
	--vis-color-ewiq-dialog-description-text-color: var(--vis-color-gs-85);
	--vis-color-ewiq-dialog-question-text-color: var(--vis-color-gs-100);
	--vis-color-ewiq-dialog-content-text-color: var(--vis-color-gs-100);
	--vis-color-ewiq-dialog-content-background: var(--vis-color-gs-20);
	--vis-color-ewi-warning: var(--vis-color-sc-y-100);
	--vis-color-ewi-info: var(--vis-color-pb-100);
	--vis-color-ewi-error: var(--vis-color-sc-r-100);
	--vis-color-ewi-question: var(--vis-color-gs-80);
	--vis-color-ewi-check: var(--vis-color-sc-g-110);
	--vis-color-dialog-box-top-line: var(--vis-color-gs-85);
	--vis-dialog-border-radius: 8px;
	--vis-dialog-box-shadow: rgba(0, 0, 0, 0.2) 0 3px 8px;
}

html[theme='dark'] {
	--vis-color-menu-tab-item-default-background-active: var(--vis-color-gs-110);
	--vis-color-menu-tab-item-default-background-focus: var(--vis-color-gs-100);
	--vis-color-menu-tab-item-default-background-hover: var(--vis-color-gs-105);
	--vis-color-menu-tab-item-default-background: var(--vis-color-gs-100);
	--vis-color-menu-tab-item-selected-background: var(--vis-color-gs-100);

	--vis-color-menu-tab-item-default: var(--vis-color-gs-50);
	--vis-color-menu-tab-item-selected-border-bottom: var(--vis-color-pb-90);
	--vis-color-menu-tab-item-selected: var(--vis-color-gs-10);

	/* ewi dialog */
	--vis-color-dialog-button-default: var(--vis-color-gs-10);
	--vis-color-text-secondary-line: var(--vis-color-gs-70);
	--vis-color-dialog-box-separator-line: var(--vis-color-gs-110);
	--vis-color-ewiq-dialog-header-text-color: var(--vis-color-gs-10);
	--vis-color-ewiq-dialog-description-text-color: var(--vis-color-gs-70);
	--vis-color-ewiq-dialog-question-text-color: var(--vis-color-gs-50);
	--vis-color-ewiq-dialog-content-text-color: var(--vis-color-gs-50);
	--vis-color-ewiq-dialog-content-background: var(--vis-color-gs-90);
}

:root {
	// Slider variables
	--vis-color-slider-track-enabled: var(--vis-color-gs-60);
	--vis-color-slider-track-disabled: var(--vis-color-gs-60);
	--vis-color-slider-track-hover: var(--vis-color-gs-60);
	--vis-color-slider-track-readonly-enabled: var(--vis-color-gs-60);
	--vis-color-slider-track-readonly-disabled: var(--vis-color-gs-60);

	--vis-color-slider-handle-fill-enabled: var(--vis-color-gs-40);
	--vis-color-slider-handle-fill-disabled: var(--vis-color-gs-30);
	--vis-color-slider-handle-fill-disabled-readonly: var(--vis-color-gs-60);
	--vis-color-slider-handle-fill-hover: var(--vis-color-gs-30);
	--vis-color-slider-handle-fill-ghost: var(--vis-color-gs-30);

	--vis-color-slider-handle-border-enabled: var(--vis-color-gs-90);
	--vis-color-slider-handle-border-disabled: var(--vis-color-gs-60);
	--vis-color-slider-handle-border-hover: var(--vis-color-gs-100);
	--vis-color-slider-handle-border-ghost: var(--vis-color-gs-60);

	--vis-color-slider-active-line-enabled: var(--vis-color-gs-90);
	--vis-color-slider-active-line-disabled: var(--vis-color-gs-60);
	--vis-color-slider-active-line-hover: var(--vis-color-gs-90);
	--vis-color-slider-active-line-readonly-enabled: var(--vis-color-gs-90);
	--vis-color-slider-active-line-readonly-disabled: var(--vis-color-gs-60);

	--vis-color-slider-step: var(--vis-color-gs-60);
	--vis-color-slider-tick-label: var(--vis-color-gs-85);
	--vis-color-slider-handle-fill-readonly-enabled: var(--vis-color-gs-90);
	--vis-color-slider-handle-fill-readonly-disabled: var(--vis-color-gs-60);

	--vis-color-icon-interactive-bg-default-enabled: var(--vis-color-gs-100);
	--vis-color-icon-interactive-bg-default-hovered: var(--vis-color-gs-70);
	--vis-color-icon-interactive-bg-default-pressed: var(--vis-color-gs-85);
	--vis-color-icon-interactive-bg-subtle-enabled: var(--vis-color-gs-85);
	--vis-color-icon-interactive-bg-subtle-hovered: var(--vis-color-gs-60);
	--vis-color-icon-interactive-bg-subtle-pressed: var(--vis-color-gs-70);
	--vis-color-icon-interactive-bg-highlight-enabled: var(--vis-color-gs-120);
	--vis-color-icon-interactive-bg-highlight-hovered: var(--vis-color-gs-80);
	--vis-color-icon-interactive-bg-highlight-pressed: var(--vis-color-gs-85);
}

html[theme='dark'] {
	--vis-color-slider-track-enabled: var(--vis-color-gs-120);
	--vis-color-slider-track-disabled: var(--vis-color-gs-120);
	--vis-color-slider-track-hover: var(--vis-color-gs-120);
	--vis-color-slider-track-readonly-enabled: var(--vis-color-gs-60);
	--vis-color-slider-track-readonly-disabled: var(--vis-color-gs-60);

	--vis-color-slider-handle-fill-enabled: var(--vis-color-gs-110);
	--vis-color-slider-handle-fill-disabled: var(--vis-color-gs-105);
	--vis-color-slider-handle-fill-disabled-readonly: var(--vis-color-gs-90);
	--vis-color-slider-handle-fill-hover: var(--vis-color-gs-30);
	--vis-color-slider-handle-fill-ghost: var(--vis-color-gs-30);

	--vis-color-slider-handle-border-enabled: var(--vis-color-gs-90);
	--vis-color-slider-handle-border-disabled: var(--vis-color-gs-60);
	--vis-color-slider-handle-border-hover: var(--vis-color-gs-100);
	--vis-color-slider-handle-border-ghost: var(--vis-color-gs-60);

	--vis-color-slider-active-line-enabled: var(--vis-color-gs-90);
	--vis-color-slider-active-line-disabled: var(--vis-color-gs-60);
	--vis-color-slider-active-line-hover: var(--vis-color-gs-90);
	--vis-color-slider-active-line-readonly-enabled: var(--vis-color-gs-90);
	--vis-color-slider-active-line-readonly-disabled: var(--vis-color-gs-60);

	--vis-color-slider-step: var(--vis-color-gs-60);
	--vis-color-slider-tick-label: var(--vis-color-gs-85);
	--vis-color-slider-handle-fill-readonly-enabled: var(--vis-color-gs-90);
	--vis-color-slider-handle-fill-readonly-disabled: var(--vis-color-gs-60);

	--vis-color-icon-interactive-bg-default-enabled: var(--vis-color-gs-50);
	--vis-color-icon-interactive-bg-default-hovered: var(--vis-color-gs-80);
	--vis-color-icon-interactive-bg-default-pressed: var(--vis-color-gs-70);
	--vis-color-icon-interactive-bg-subtle-enabled: var(--vis-color-gs-70);
	--vis-color-icon-interactive-bg-subtle-hovered: var(--vis-color-gs-90);
	--vis-color-icon-interactive-bg-subtle-pressed: var(--vis-color-gs-85);
	--vis-color-icon-interactive-bg-highlight-enabled: var(--vis-color-gs-10);
	--vis-color-icon-interactive-bg-highlight-hovered: var(--vis-color-gs-70);
	--vis-color-icon-interactive-bg-highlight-pressed: var(--vis-color-gs-60);
}

/* Progress ring */
:root {
	--vis-progress-ring-rail-size: 1px;
	--vis-progress-ring-rail-opacity: 1;
	--vis-progress-ring-animation-duration: 1.33s;
	--vis-progress-ring-animation-timing: cubic-bezier(0.4, 0.1, 0.6, 0.9);
	--vis-progress-ring-rail-color: var(--vis-color-progress-ring-default-background);
	--vis-progress-ring-progress-color: var(--vis-color-progress-ring-default-foreground);
	/* Default */
	--vis-color-progress-ring-default-background: var(--vis-color-gs-50);
	--vis-color-progress-ring-default-foreground: var(--vis-color-gs-85);
	/* Highlight */
	--vis-color-progress-ring-highlight-background: var(--vis-color-gs-50);
	--vis-color-progress-ring-highlight-foreground: var(--vis-color-pb-90);
	/* Primary Highlight */
	--vis-color-progress-ring-primary-highlight-background: var(--vis-color-gs-10);
	--vis-color-progress-ring-primary-highlight-foreground: var(--vis-color-gs-10);
}

html[theme='dark'] {
	/* Default */
	--vis-color-progress-ring-default-background: var(--vis-color-gs-90);
	--vis-color-progress-ring-default-foreground: var(--vis-color-gs-40);
	/* Highlight */
	--vis-color-progress-ring-highlight-background: var(--vis-color-gs-90);
	--vis-color-progress-ring-highlight-foreground: var(--vis-color-pb-90);
	/* Primary Highlight */
	--vis-color-progress-ring-primary-highlight-background: var(--vis-color-gs-10);
	--vis-color-progress-ring-primary-highlight-foreground: var(--vis-color-gs-10);
}
