// Import for custom overlay module styles
@import '@angular/cdk/overlay-prebuilt.css';
@import 'variables';
@import 'material';

html {
	&,
	&[theme='dark'] {
		background: var(--vis-color-app-bg) !important;
	}
}

body {
	font: var(--vis-typography-body);
	color: var(--vis-color-text-default);
	overflow: hidden;
}

[color='error'] {
	color: var(--vis-color-error);
}
[bgColor='error'] {
	background-color: var(--vis-color-error);
}

[color='warning'] {
	color: var(--vis-color-warning);
}
[bgColor='warning'] {
	background-color: var(--vis-color-warning);
}

[color='success'] {
	color: var(--vis-color-success);
}
[bgColor='success'] {
	background-color: var(--vis-color-success);
}

[color='interactive'] {
	color: var(--vis-color-interactive);
}
[bgColor='interactive'] {
	background-color: var(--vis-color-interactive);
}

[color='accent'] {
	color: var(--vis-color-accent);
}
[bgColor='accent'] {
	background-color: var(--vis-color-accent);
}

[color='purple'] {
	color: var(--vis-color-purple);
}
[bgColor='purple'] {
	background-color: var(--vis-color-purple);
}

[color='turquoise'] {
	color: var(--vis-color-turquoise);
}
[bgColor='turquoise'] {
	background-color: var(--vis-color-turquoise);
}

[color='pink'] {
	color: var(--vis-color-pink);
}
[bgColor='pink'] {
	background-color: var(--vis-color-pink);
}

[color='orange'] {
	color: var(--vis-color-orange);
}
[bgColor='orange'] {
	background-color: var(--vis-color-orange);
}

[color='brown'] {
	color: var(--vis-color-brown);
}
[bgColor='brown'] {
	background-color: var(--vis-color-brown);
}

[color='indigo'] {
	color: var(--vis-color-indigo);
}
[bgColor='indigo'] {
	background-color: var(--vis-color-indigo);
}

[color='white'] {
	color: var(--vis-color-white);
}
[bgColor='white'] {
	background-color: var(--vis-color-white);
}

[color='black'] {
	color: var(--vis-color-black);
}
[bgColor='black'] {
	background-color: var(--vis-color-black);
}

[color='lightgray'] {
	color: var(--vis-color-lightgray);
}
[bgColor='lightgray'] {
	background-color: var(--vis-color-lightgray);
}

[color='gray'] {
	color: var(--vis-color-gray);
}
[bgColor='gray'] {
	background-color: var(--vis-color-gray);
}

[color='darkgray'] {
	color: var(--vis-color-ultradark-gray);
}
[bgColor='darkgray'] {
	background-color: var(--vis-color-ultradark-gray);
}

// Accent 3 (brown)
[color='8'],
[color='accent3'],
.zui-color-fg-accent3 {
	color: var(--zui-color-ac-br-110);
}
[bgColor='8'],
[bgColor='accent3'],
.zui-color-bg-accent3 {
	background-color: var(--zui-color-ac-br-110);
}

// Accent 4 (orange)
[color='9'],
[color='accent4'],
.zui-color-fg-accent4 {
	color: var(--zui-color-sc-o-100);
}
[bgColor='9'],
[bgColor='accent4'],
.zui-color-bg-accent4 {
	background-color: var(--zui-color-sc-o-100);
}

// Accent 5 (magenta)
[color='10'],
[color='accent5'],
.zui-color-fg-accent5 {
	color: var(--zui-color-ac-v-90);
}
[bgColor='10'],
[bgColor='accent5'],
.zui-color-bg-accent5 {
	background-color: var(--zui-color-ac-v-90);
}

// TextHighlight
.text-highlight,
.text-highlight-light:not(.text-highlight-dark) {
	background-color: var(--zui-color-pb-60);
}
html[theme='dark'] .text-highlight:not(.text-highlight-light),
.text-highlight-dark {
	background-color: var(--vis-color-accent);
}

// TODO: workaround for zui icons inside zui-inline-message
// having wrong color in dark theme (zui 1.25.5)
zui-inline-message [zuiicon]:not([color]) {
	color: var(--zui-color-text-default);
}

// Clipboard
.vis-clipboard-button {
	display: inline-block;
	margin-left: calc(var(--vis-gu) * 0.5);
	vertical-align: middle;
	margin-top: calc(var(--vis-gu) * -0.25);
}

vis-item .vis-clipboard-button {
	margin-top: calc(var(--vis-gu) * 0.3);
}

// ZUI (Interactive) Icon
zui-interactive-icon[emphasis='active'] {
	color: var(--vis-color-interactive);
}

zui-interactive-icon[disabled] {
	color: var(--vis-color-grey);
}

zui-interactive-icon:hover:not([disabled]) {
	opacity: var(--vis-disabled-opacity);
}

zui-interactive-icon,
vis-icon {
	&[inline] {
		display: inline-block;
		vertical-align: middle;
		line-height: inherit;
		margin-right: calc(var(--vis-gu) * 0.5);
	}
}

// Hyperlink styling
a {
	color: var(--vis-color-text-default);

	&:hover {
		color: var(--vis-color-interactive);
	}
}

[theme='light'] {
	--zui-bgc-content-wrapper: var(--zui-color-gs-35);
}
[theme='dark'] {
	--zui-bgc-content-wrapper: var(--zui-color-gs-100);
}

// this lifts the zui portal (responsible for e.g. select menus) higher than the cdk-overlay
zui-portal {
	--zui-portal-level: 1000;
}

pre,
code {
	font: var(--vis-typography-code);
	color: var(--vis-color-text-default);
}

// this lifts the zui portal (responsible for e.g. select menus) higher than the cdk-overlay
zui-portal {
	--zui-portal-level: 1000;
}

.mat-mdc-progress-bar {
	.mdc-linear-progress__bar-inner {
		border-color: var(--vis-color-accent) !important;
	}

	.mdc-linear-progress__buffer-bar {
		background-color: transparent !important;
	}
}

.vis-overlay-blur {
	inset: 0px;
	position: absolute;
	z-index: 106;

	@supports (backdrop-filter: blur(4px)) {
		backdrop-filter: blur(4px);
	}
	@supports not (backdrop-filter: blur(4px)) {
		&::before {
			content: '';
			display: block;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			position: absolute;
			background-color: var(--vis-overlay-blur-bg);
			opacity: 0.8;
		}
	}

	> div {
		display: flex;
		align-items: center;
		justify-content: center;
		max-height: 300px;
		height: 100%;
		width: 100%;

		> * {
			max-width: 300px;
		}
	}
}

vis-split {
	&.vis-split-horizontal > .vis-split-area-1 .vis-split-splitbox {
		margin-left: auto;
	}

	&.vis-split-vertical > .vis-split-area-1 .vis-split-splitbox {
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
	}
}

.cursor-pointer {
	cursor: pointer;
}

.cursor-help {
	cursor: help;
}

.cursor-not-allowed {
	cursor: not-allowed;
}

vis-box .small .content {
	zui-section-divider,
	vis-section-divider {
		margin-left: calc(var(--vis-gu) * -1) !important;
		margin-right: calc(var(--vis-gu) * -1) !important;
	}
}

vis-box .full .content {
	zui-section-divider,
	vis-section-divider {
		margin-left: calc(var(--vis-gu) * -2) !important;
		margin-right: calc(var(--vis-gu) * -2) !important;
	}
}

zui-section-divider,
vis-section-divider {
	margin-bottom: calc(var(--vis-gu) * 3) !important;

	&:not(:first-child) {
		margin-top: calc(var(--vis-gu) * 3) !important;
	}
}

zui-section-divider[sticky],
vis-section-divider[sticky] {
	position: sticky;
	top: 0;
	z-index: 110; // to place over material table sticky header with z-index: 100
}

[vis-layout] {
	width: inherit;
	height: inherit;
	grid-gap: var(--vis-gu);
	display: flex;
	flex-wrap: wrap;

	&[vis-layout~='primary'] {
		width: 100%;
		height: 100%;
		padding-left: var(--vis-gu);
	}

	&[vis-layout~='padding'] {
		padding: var(--vis-gu);
	}

	&[vis-layout~='large-gap'] {
		grid-gap: calc(var(--vis-gu) * 3);
	}

	&[vis-layout~='flex'] {
		width: auto;
		height: auto;

		&:not([vis-layout~='dense']) > *:not([vis-layout~='compact']) {
			flex: 1;
		}
	}

	&[vis-layout~='grid'] {
		display: grid;
		grid-auto-rows: max-content;
	}

	&[vis-layout~='horizontal'] {
		> * {
			flex: 1;
			height: auto;
			overflow: auto;
			max-height: 100%;
		}
	}

	&[vis-layout~='content'] {
		display: grid;
		grid-template-columns: 100%;
		grid-template-rows: 100%;

		> * {
			overflow: auto;
		}
	}

	&[vis-layout~='header-content'] {
		display: grid;
		grid-template-rows: 100px calc(100% - 100px - var(--vis-gu));

		> :nth-child(2) {
			overflow: auto;
		}
	}

	&[vis-layout~='header-content-footer'] {
		display: grid;
		grid-template-rows: 100px calc(100% - 200px - (var(--vis-gu) * 2)) 100px;

		> :nth-child(2) {
			overflow: auto;
		}
	}

	&[vis-layout~='content-footer'] {
		display: grid;
		grid-template-rows: calc(100% - 100px - var(--vis-gu)) 100px;

		> :nth-child(1) {
			overflow: auto;
		}
	}

	&[vis-layout~='vertical'] {
		flex-flow: column;
	}

	&[vis-layout~='centered'] {
		justify-content: center;
		align-items: center;
	}

	&[vis-layout~='end'] {
		justify-content: flex-end;
		align-items: flex-end;
	}

	&[vis-layout~='between'] {
		justify-content: space-between;
		align-items: center;
	}

	&[vis-layout~='auto'] {
		height: auto !important;
		max-height: 100%;

		> * {
			height: auto;
		}

		&[vis-layout~='small'] {
			> * {
				width: 250px;
			}

			&[vis-layout~='fixed'] {
				> * {
					height: 200px;
				}
			}
		}

		&[vis-layout~='medium'] {
			> * {
				width: 400px;
			}

			&[vis-layout~='fixed'] {
				> * {
					height: 250px;
				}
			}
		}

		&[vis-layout~='large'] {
			> * {
				width: 600px;
			}

			&[vis-layout~='fixed'] {
				> * {
					height: 400px;
				}
			}
		}
	}
}

vis-item {
	.item-label {
		&.label-fixed-width {
			vis-item-label {
				max-width: var(--item-label-width-fixed);
				text-overflow: ellipsis;
				overflow: hidden;
			}

			&.has-desc vis-item-label {
				max-width: calc(var(--item-label-width-fixed) - var(--vis-gu) * 3);
			}
		}
	}

	.item-content {
		> p {
			margin: calc(var(--vis-gu) * 0.1) 0 0 0;
			display: block;
		}

		> zui-textfield {
			margin-top: calc(var(--vis-gu) * -0.7);
		}

		> zui-textarea,
		> zui-checkbox {
			margin-top: calc(var(--vis-gu) * -0.1);
			display: block;
		}

		zui-checkbox:empty {
			margin-top: calc(var(--vis-gu) * 0.5);
		}

		> zui-select,
		> vis-select {
			margin-top: calc(var(--vis-gu) * -0.8);
			display: inline-block;
			width: 100%;
			min-width: 50px;
		}

		> zui-textfield-date-picker,
		> zui-date-picker-input,
		> vis-date-picker-input,
		> vis-input {
			margin-top: calc(var(--vis-gu) * -0.5);
			display: block;
		}

		> vis-input {
			width: 100%;
		}

		> :not(vis-form-error):not(.bottom-placeholder):not(.hint) {
			line-height: var(--zui-label1-line-height);
		}

		> * {
			display: inline-flex;
			margin-top: calc(var(--vis-gu) * 0.5);
		}
	}
}

// only display flex-wrap for larger screens.
// On mobile it still should wrap.
@media screen and (min-width: 500px) {
	vis-box-footer {
		mat-paginator .mat-paginator-container,
		mat-paginator .mat-mdc-paginator-container {
			flex-wrap: nowrap;
		}
	}
}

vis-box-footer {
	mat-paginator .mat-paginator-container,
	mat-paginator .mat-mdc-paginator-container {
		min-height: unset !important;
		max-height: calc(var(--vis-gu) * 4);
	}

	vis-item {
		align-items: center !important;

		.item-label {
			margin-top: 0 !important;
			min-width: 80px;
		}

		.item-content {
			margin-top: 0 !important;

			.bottom-placeholder {
				display: none;
			}

			> zui-textfield {
				margin-top: 0;
			}

			> vis-input-date-picker {
				margin-top: 0;
			}

			> zui-checkbox {
				margin-top: calc(var(--vis-gu) * 0.5);

				&:empty {
					margin-top: var(--vis-gu);
				}
			}

			> zui-select,
			> vis-select,
			> vis-form-field {
				margin-top: calc(var(--vis-gu) * -0.25);
			}
		}
	}
}

vis-searchbar-input {
	//Removes the border from the searchbar
	.mdc-line-ripple {
		display: none;
	}

	//Fix to disable autocomplete arrow in searchbar
	.searchbar-container.disabled {
		.mat-mdc-form-field-icon-suffix {
			pointer-events: none;
		}
	}
}

// TODO: workaround for missing font (zui bug)
zui-about-screen {
	font: var(--vis-typography-body);
	color: var(--zui-color-text-default);
}

// TODO: workaround for missing color (zui bug)
html[theme='dark'] zui-about-screen {
	color: var(--vis-color-gs-100) !important;
}

zui-about-screen {
	max-height: 90vh;
}

.accordion-child-opened {
	flex: 1 !important;
	min-height: 0;
}

.accordion-child-closed {
	flex: unset !important;
}

.cdk-overlay-pane,
.cdk-overlay-pane zui-dialogbox {
	max-height: 99vh;
	font: var(--vis-typography-body);
	color: var(--zui-color-text-default);
}

//Datepicker
.datepicker-input-hidden {
	width: 0;
	height: 0;
	pointer-events: none;
	overflow: hidden;
	opacity: 0;
}

.date-input {
	display: flex;
	align-items: center;

	&__icon {
		margin-left: var(--vis-gu);
	}
}

// Styling zui

zui-textfield,
zui-textarea {
	width: 100%;
}

zui-textarea {
	---zui-textarea-textarea-spacing-horizontal: 0;
	---zui-textarea-textarea-spacing-vertical: 0;
}

// TODO: the scaled arrow in the :hovered zui-select produces an overflow which we need to hide (the scaling is not accessible directly)
zui-select {
	--zui-select-menu-elevation: 3;
	overflow: hidden;
}

zui-pill {
	white-space: pre;
}

zui-searchbar-input {
	max-width: 100%;
	width: 100%;
	margin-bottom: var(--vis-gu);
}

zui-interactive-icon[inline] {
	display: inline-block;
	vertical-align: middle;
	line-height: inherit;
	margin-right: calc(var(--vis-gu) * 0.5);
}

zui-ewiq-dialog {
	border: none;
}

zui-menu-tab-bar {
	justify-content: flex-start;
	flex: none;
}

zui-tag[dark] {
	--zui-color-tag-text: var(--vis-color-white);
	--zui-color-tag-background-enabled: var(--vis-color-white);
}

/*
* TODO: Workaround for the zui-about-screen
*/
html[theme='dark'] zui-about-screen {
	color: var(--vis-color-gs-100) !important;
}

.vis-select-menu {
	&.mat-mdc-menu-panel {
		max-width: 100% !important;
	}
}

vis-headerbar,
zui-headerbar {
	.mat-mdc-select-value-text {
		color: var(--vis-color-ultradark-gray);
	}

	a {
		color: var(--vis-color-ultradark-gray);

		&:hover {
			color: var(--vis-color-interactive);
		}
	}
}

// ZUI Typography enforcement
// Set here instead of using registerTypefaces from @zeiss/zui-theme-base
// which also uses Segoe UI and asian Noto Sans in certain cases
:root {
	--zui-font-family: 'Noto Sans';

	--zui-h0-font-style: normal;
	--zui-h0-font-weight: 300;
	--zui-h0-font-size: 46px;
	--zui-h0-line-height: 1.56521739;

	--zui-h1-font-style: normal;
	--zui-h1-font-weight: 300;
	--zui-h1-font-size: 31px;
	--zui-h1-line-height: 1.5483871;

	--zui-h2-font-style: normal;
	--zui-h2-font-weight: 300;
	--zui-h2-font-size: 22px;
	--zui-h2-line-height: 1.63636364;

	--zui-h3-font-style: normal;
	--zui-h3-font-weight: 300;
	--zui-h3-font-size: 19px;
	--zui-h3-line-height: 1.47368421;

	--zui-h4-font-style: normal;
	--zui-h4-font-weight: 400;
	--zui-h4-font-size: 15px;
	--zui-h4-line-height: 1.6;

	--zui-subtitle1-font-style: normal;
	--zui-subtitle1-font-weight: 600;
	--zui-subtitle1-font-size: 13px;
	--zui-subtitle1-line-height: 1.53846154;

	--zui-subtitle2-font-style: normal;
	--zui-subtitle2-font-weight: 700;
	--zui-subtitle2-font-size: 11px;
	--zui-subtitle2-line-height: 1.45454545;

	--zui-body-font-style: normal;
	--zui-body-font-weight: 400;
	--zui-body-font-size: 13px;
	--zui-body-line-height: 1.53846154;

	--zui-caption-font-style: normal;
	--zui-caption-font-weight: 400;
	--zui-caption-font-size: 11px;
	--zui-caption-line-height: 1.45454545;

	--zui-label1-font-style: normal;
	--zui-label1-font-weight: 600;
	--zui-label1-font-size: 11px;
	--zui-label1-line-height: 1.45454545;

	--zui-label2-font-style: normal;
	--zui-label2-font-weight: 700;
	--zui-label2-font-size: 10px;
	--zui-label2-line-height: 1.2;
}
