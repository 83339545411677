@use 'sass:map';
@use '@angular/material' as mat;
@import '../material-utils';

@mixin color($theme) {
	$color-config: mat.m2-get-color-config($theme);
	$primary-palette: map.get($color-config, 'primary');

	.mat-primary {
		--mat-full-pseudo-checkbox-selected-icon-color: var(--vis-border-color-hover);
		--mdc-full-pseudo-checkbox-selected-checkmark-color: var(--vis-color-text-on-dark-bg);
		--mat-full-pseudo-checkbox-unselected-icon-color: var(--vis-border-color);
		--mat-full-pseudo-checkbox-disabled-selected-checkmark-color: var(--vis-color-text-on-dark-bg);
		--mat-full-pseudo-checkbox-disabled-unselected-icon-color: var(--vis-border-color);
		--mat-full-pseudo-checkbox-disabled-selected-icon-color: var(--vis-border-color-hover);
		--mat-minimal-pseudo-checkbox-selected-checkmark-color: var(--vis-color-gs-100);
		--mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: var(
			--vis-color-text-on-dark-bg
		);
	}

	.mat-pseudo-checkbox.mat-pseudo-checkbox {
		width: var(--vis-checkbox-size);
		height: var(--vis-checkbox-size);
		margin-right: var(--vis-gu);
		border-width: 1px;
		border-color: var(--vis-border-color);
		background: var(--vis-color-interactive-element-bg-default-enabled);

		&.mat-pseudo-checkbox-full.mat-pseudo-checkbox-checked {
			border-color: var(--vis-border-color-hover);
			background: var(--vis-color-interactive-element-bg-activated-enabled);

			&::after {
				width: 4px;
				height: 2px;
				border-left-width: 1.6px;
				border-bottom-width: 1.6px;
				top: -1.4px;
				color: var(--vis-color-white);
			}
		}
	}
}

@mixin typography($theme) {
	.mat-primary {
		letter-spacing: 0;
		font: var(--vis-typography-body);
		color: var(--vis-color-text-default);
	}
}

@mixin theme($theme) {
	$color-config: mat.m2-get-color-config($theme);

	@if $color-config != null {
		@include color($theme);
	}

	$typography-config: mat.m2-get-typography-config($theme);

	@if $typography-config != null {
		@include typography($theme);
	}
}
