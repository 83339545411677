@use 'sass:map';
@use '@angular/material' as mat;

$vis-mat-interactive-palette: (
	50: #d6e7ff,
	100: #c2daff,
	200: #a6c9ff,
	300: #85b6ff,
	400: #5c9dff,
	500: var(--vis-color-interactive),
	600: #006aed,
	700: #0050f2,
	800: #003ecd,
	900: #0029af,
	A100: var(--vis-color-white),
	A200: #d8e1ff,
	A400: #a5b9ff,
	A700: #8ba5ff,
	contrast: (
		50: var(--vis-color-darkgray),
		100: var(--vis-color-darkgray),
		200: var(--vis-color-darkgray),
		300: var(--vis-color-darkgray),
		400: var(--vis-color-white),
		500: var(--vis-color-white),
		600: var(--vis-color-white),
		700: var(--vis-color-white),
		800: var(--vis-color-white),
		900: var(--vis-color-white),
		A100: var(--vis-color-darkgray),
		A200: var(--vis-color-darkgray),
		A400: var(--vis-color-darkgray),
		A700: var(--vis-color-darkgray),
	),
);

$vis-mat-warn-palette: (
	50: #fce4e4,
	100: #f8bcbc,
	200: #f38f8f,
	300: #ee6262,
	400: #eb4040,
	500: #e71e1e,
	600: #e41a1a,
	700: #e01616,
	800: #dd1212,
	900: #d70a0a,
	A100: var(--vis-color-midgray),
	A200: #ffcece,
	A400: #ff9b9b,
	A700: #ff8181,
	contrast: (
		50: var(--vis-color-ultradark-gray),
		100: var(--vis-color-ultradark-gray),
		200: var(--vis-color-ultradark-gray),
		300: var(--vis-color-ultradark-gray),
		400: var(--vis-color-midgray),
		500: var(--vis-color-midgray),
		600: var(--vis-color-midgray),
		700: var(--vis-color-midgray),
		800: var(--vis-color-midgray),
		900: var(--vis-color-midgray),
		A100: var(--vis-color-ultradark-gray),
		A200: var(--vis-color-ultradark-gray),
		A400: var(--vis-color-ultradark-gray),
		A700: var(--vis-color-ultradark-gray),
	),
);

$vis-mat-primary-palette: (
	50: var(--vis-color-gs-60),
	100: var(--vis-color-gs-70),
	200: var(--vis-color-gs-70),
	300: var(--vis-color-gs-80),
	400: var(--vis-color-gs-85),
	500: var(--vis-color-gs-90),
	// have to hardcode as scss variable caused error in material
	600: #404952,
	700: var(--vis-color-gs-100),
	800: var(--vis-color-gs-105),
	900: var(--vis-color-gs-110),
	A100: #8796d9,
	A200: #576cca,
	A400: #334dbe,
	A700: var(--vis-color-indigo),
	contrast: (
		50: var(--vis-color-ultradark-gray),
		100: var(--vis-color-ultradark-gray),
		200: var(--vis-color-ultradark-gray),
		300: var(--vis-color-lightgray),
		400: var(--vis-color-lightgray),
		500: var(--vis-color-lightgray),
		600: var(--vis-color-lightgray),
		700: var(--vis-color-lightgray),
		800: var(--vis-color-lightgray),
		900: var(--vis-color-lightgray),
		A100: var(--vis-color-lightgray),
		A200: var(--vis-color-lightgray),
		A400: var(--vis-color-lightgray),
		A700: var(--vis-color-lightgray),
	),
);

/* Dark theme pallet */
$vis-mat-primary-dark: (
	50: var(--vis-color-gs-70),
	100: var(--vis-color-gs-80),
	200: var(--vis-color-gs-85),
	300: var(--vis-color-gs-90),
	// have to hardcode as scss variable caused error in material
	400: #404952,
	500: var(--vis-color-gs-100),
	600: var(--vis-color-gs-105),
	700: var(--vis-color-gs-110),
	800: var(--vis-color-gs-115),
	900: var(--vis-color-gs-120),
	A100: #8796d9,
	A200: #576cca,
	A400: #334dbe,
	A700: var(--vis-color-indigo),
	contrast: (
		50: var(--vis-color-ultradark-gray),
		100: var(--vis-color-ultradark-gray),
		200: var(--vis-color-ultradark-gray),
		300: var(--vis-color-ultradark-gray),
		400: var(--vis-color-lightgray),
		500: var(--vis-color-lightgray),
		600: var(--vis-color-lightgray),
		700: var(--vis-color-lightgray),
		800: var(--vis-color-lightgray),
		900: var(--vis-color-lightgray),
		A100: #8796d9,
		A200: #576cca,
		A400: #334dbe,
		A700: var(--vis-color-indigo),
	),
);

/* Typography */

$vis-typography-config: mat.m2-define-typography-config(
	var(--vis-font-family),
	$headline-1:
		mat.m2-define-typography-level(
			var(--vis-h1-font-size),
			var(--vis-h1-line-height),
			var(--vis-h1-font-weight),
			var(--vis-font-family),
			0
		),
	$headline-2:
		mat.m2-define-typography-level(
			var(--vis-h2-font-size),
			var(--vis-h2-line-height),
			var(--vis-h2-font-weight),
			var(--vis-font-family),
			0
		),
	$headline-3:
		mat.m2-define-typography-level(
			var(--vis-h3-font-size),
			var(--vis-h3-line-height),
			var(--vis-h3-font-weight),
			var(--vis-font-family),
			0
		),
	$headline-4:
		mat.m2-define-typography-level(
			var(--vis-h4-font-size),
			var(--vis-h4-line-height),
			var(--vis-h4-font-weight),
			var(--vis-font-family),
			0
		),
	$headline-5:
		mat.m2-define-typography-level(
			var(--vis-h5-font-size),
			var(--vis-h5-line-height),
			var(--vis-h5-font-weight),
			var(--vis-font-family),
			0
		),
	$subtitle-1:
		mat.m2-define-typography-level(
			var(--vis-subtitle1-font-size),
			var(--vis-subtitle1-line-height),
			var(--vis-subtitle1-font-weight),
			var(--vis-font-family),
			0
		),
	$subtitle-2:
		mat.m2-define-typography-level(
			var(--vis-subtitle2-font-size),
			var(--vis-subtitle2-line-height),
			var(--vis-subtitle2-font-weight),
			var(--vis-font-family),
			0
		),
	$body-1:
		mat.m2-define-typography-level(
			var(--vis-body-font-size),
			var(--vis-body-line-height),
			var(--vis-font-family),
			0
		),
	$caption:
		mat.m2-define-typography-level(
			var(--vis-body-font-size),
			var(--vis-body-line-height),
			var(--vis-body-font-weight),
			0
		)
);

$vis-primary: mat.m2-define-palette($vis-mat-primary-palette, 700, 200, 700, 700);
$vis-accent: mat.m2-define-palette($vis-mat-interactive-palette, 900);
$vis-warn: mat.m2-define-palette($vis-mat-warn-palette, 500);
