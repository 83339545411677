@use 'sass:map';
@use '@angular/material' as mat;

@use 'modules/form-field-theme' as form-field;
@use 'modules/chip-theme' as chips;
@use 'modules/datepicker-theme' as dateicker;
@use 'modules/table-theme' as table;
@use 'modules/button-theme' as button;
@use 'modules/checkbox-theme' as checkbox;
@use 'modules/radio-button-theme' as radio;
@use 'modules/button-toggle-theme' as button-toggle;
@use 'modules/slide-toggle-theme' as slide-toggle;
@use 'modules/tabs-theme' as tabs;
@use 'modules/pseudo-checkbox-theme' as pseudo-checkbox;
@use 'modules/select-theme' as select;
@use 'modules/list-theme' as list;
@use 'modules/paginator-theme' as pagnator;
@use 'modules/menu-theme' as menu;
@use 'modules/progress-bar-theme' as progress-bar;
@use 'modules/slider-theme' as slider;
@use 'modules/dialog-theme' as dialog;
@use 'modules/autocomplete-theme' as autocomplete;
@use 'modules/progress-spinner-theme' as progress-spinner;

@use 'palettes/light-theme' as light-theme;
@use 'palettes/dark-theme' as dark-theme;

@include mat.core();

@mixin custom-material-theme($custom-theme) {
	@include form-field.theme($custom-theme);
	@include mat.form-field-density(-5);

	@include dateicker.theme($custom-theme);
	@include mat.datepicker-density(-1);

	@include table.theme($custom-theme);
	@include mat.table-density(-4);

	@include button.theme($custom-theme);
	@include mat.button-density(-1);

	@include chips.theme($custom-theme);
	@include mat.chips-density(0);

	@include checkbox.theme($custom-theme);
	@include mat.checkbox-density(-5);

	@include radio.theme($custom-theme);
	@include mat.radio-density(-5);

	@include button-toggle.theme($custom-theme);

	@include list.theme($custom-theme);

	@include pagnator.theme($custom-theme);

	@include slide-toggle.theme($custom-theme);

	@include select.theme($custom-theme);

	@include autocomplete.theme($custom-theme);

	@include tabs.theme($custom-theme);

	@include pseudo-checkbox.theme($custom-theme);

	@include menu.theme($custom-theme);

	@include progress-bar.theme($custom-theme);

	@include slider.theme($custom-theme);

	@include dialog.theme($custom-theme);

	@include progress-spinner.theme($custom-theme);
}

@include mat.all-component-themes(light-theme.$vis-theme-light);
@include custom-material-theme(light-theme.$vis-theme-light);

[theme='dark'] {
	@include mat.all-component-colors(dark-theme.$vis-theme-dark);
	@include custom-material-theme(dark-theme.$vis-theme-dark);
}

/*
* Colors
*/
.mat-info {
	background-color: var(--vis-color-accent) !important;
}
.mat-success {
	background-color: var(--vis-color-success) !important;
}
.mat-warning {
	background-color: var(--vis-color-warning) !important;
}
.mat-error {
	background-color: var(--vis-color-error) !important;
	color: var(--vis-color-white) !important;
}
.mat-grey {
	background-color: var(--vis-color-lightgray) !important;
}
.mat-white {
	background-color: var(--vis-color-white) !important;
}

.mat-mdc-text-field-wrapper {
	padding: 0 !important;
}

.mdc-notched-outline__leading {
	display: none !important;
}

.mdc-notched-outline__trailing {
	border-right: 0 !important;
	border-top: 0 !important;
	border-radius: 0 !important;
	border-width: var(--vis-line-strength-thin) !important;
	border-color: var(--vis-border-color) !important;
}

.mdc-text-field:hover .mdc-notched-outline__trailing {
	border-width: var(--vis-line-strength-medium) !important;
	border-color: var(--vis-color-select-bottom-line-selected-hovered) !important;
}

.mdc-text-field--focused,
.mat-focused {
	.mdc-notched-outline__trailing {
		border-width: var(--vis-line-strength-thin) !important;
		border-color: var(--vis-color-select-bottom-line-opened-enabled) !important;
	}
}

.mat-form-field-infix {
	padding: 0.4375em 0;
	border-top: 0.84375em solid transparent;
}

vis-action-row .mat-mdc-paginator-container {
	height: 42px;
	min-height: unset;

	flex-wrap: nowrap;
}

[media='mobile'] mat-mdc-paginator-page-size {
	display: none;
}

vis-action-row .mat-mdc-paginator-container {
	height: 42px;
	min-height: unset;

	flex-wrap: nowrap;
}

/*
* Hide ripple effects
*/
.mat-mdc-radio-ripple,
.mat-mdc-slide-toggle-ripple,
.mat-mdc-checkbox-ripple {
	height: 0 !important;
	width: 0 !important;
}

/*
* Dialogs
*/
.mat-mdc-dialog-container .mdc-dialog__surface {
	border-radius: var(--vis-gu) !important;
}

/*
* TODO: Workaround for the zui-about-screen
*/
html[theme='dark'] zui-about-screen {
	color: var(--vis-color-gs-100) !important;
}

/*
* TODO: Workaround to hide BufferBar in MatProgessBar (Buffer is showing even if there is no Buffer set)
*/
.mat-progress-bar .mat-progress-bar-background {
	display: none;
}

/*
* Datepicker in zui textfield's slot="interactive-icon"
*/
zui-textfield mat-datepicker-toggle button.mat-mdc-button-base.mdc-icon-button {
	width: 18px !important;
	height: 18px !important;
	line-height: 18px !important;
	padding: 0 !important;

	.mat-mdc-button-touch-target {
		height: 18px;
		width: 18px;
	}

	svg {
		width: 18px;
		height: 18px;
	}
}

/*
*Tree
*/
.mat-tree,
html[theme='dark'] .mat-tree {
	background: transparent;
}

// Mat select

div.mdc-menu-surface.mat-mdc-select-panel {
	min-width: 80px;
	padding: 0;
}

html[theme='dark'] .mdc-menu-surface.mat-mdc-select-panel {
	background: var(--vis-color-gs-100);
}

// Tooltip

.mat-mdc-tooltip {
	> div {
		color: var(--vis-tooltip-color-text) !important;
		background: var(--vis-color-text-box-bg) !important;
		border-color: var(--vis-tooltip-border-color) !important;
		border-style: solid !important;
		border-width: var(--vis-line-strength-thin) !important;
		box-shadow: var(--vis-box-shadow-default) !important;
		font: var(--vis-typography-caption) !important;
		padding: var(--vis-gu) !important;
		border-radius: 0 !important;
	}
}
