@use 'sass:map';
@use '@angular/material' as mat;
@import '../material-utils';

:root {
	--vis-color-text-header: var(--vis-color-gs-120);
}

html[theme='dark'] {
	--vis-color-text-header: var(--vis-color-gs-10);
}

@mixin color($theme) {
	table.mat-mdc-table {
		--vis-row-height: 26px;

		--mat-table-header-container-height: calc(var(--vis-gu) * 3);
		--mat-table-footer-container-height: var(--vis-row-height);
		--mat-table-row-item-container-height: var(--vis-row-height);

		--mat-table-header-headline-font: var(--vis-typography-label2);
		--mat-table-header-headline-line-height: var(--vis-subtitle2-line-height);
		--mat-table-header-headline-size: var(--vis-subtitle2-font-size);
		--mat-table-header-headline-weight: 400;
		--mat-table-row-item-label-text-line-height: var(--vis-body-line-height);
		--mat-table-row-item-label-text-size: var(--vis-table-font-size);
		--mat-table-row-item-label-text-weight: 400;
		--mat-table-row-item-label-text-tracking: 0;
		--mat-table-footer-supporting-text-font: var(--vis-font-family);
		--mat-table-footer-supporting-text-line-height: var(--vis-body-line-height);
		--mat-table-footer-supporting-text-size: var(--vis-table-font-size);
		--mat-table-footer-supporting-text-weight: 400;
		--mat-table-footer-supporting-text-tracking: 0;

		--mat-table-background-color: transparent;
		--mat-table-header-headline-color: var(--vis-color-text-header);
		--mat-table-row-item-label-text-color: var(--vis-color-text-default);
		--mat-table-row-item-outline-color: var(--vis-color-interactive-element-bg-default-hovered);
		--mat-sort-arrow-color: var(--vis-color-text-default);

		tbody tr.selected,
		tbody tr.selected code,
		tbody tr.selected pre {
			font-weight: 700;
			background: var(--vis-color-third-hierarchy-bg-default-enabled);
		}

		.mat-mdc-header-row {
			font-weight: 700;
			text-transform: uppercase;
		}
	}

	tbody {
		tr {
			transition: background 0.3s;
		}

		tr.mat-mdc-row:hover:not(.col-no-hover) {
			transition: none;
			background: var(--vis-color-third-hierarchy-bg-default-enabled);
		}

		tr.changed td:first-of-type {
			border-left-style: solid;
			border-left-width: calc(var(--vis-gu) * 0.25);
			border-left-color: var(--vis-color-interactive-element-bg-default-hovered);
		}
	}

	th.mat-mdc-header-cell,
	td.mat-mdc-cell,
	td.mat-mdc-cell.mdc-data-table__cell,
	td.mat-mdc-footer-cell {
		padding: calc(var(--vis-gu) * 0.5) calc(var(--vis-gu) * 1.5) !important;
		vertical-align: top;
	}

	.mdc-data-table__cell,
	.mdc-data-table__header-cell {
		padding: 0 calc(var(--vis-gu) * 1.5);
	}

	th.mat-mdc-header-cell {
		text-transform: uppercase;
	}

	.mat-sort-header-content {
		text-align: start !important;
	}

	// set second header-row's top to the height of the first header-row
	// (when .tall-header, e.g. when containing vis-dividers) if sticky
	.mat-mdc-header-row.tall-header + .mat-mdc-header-row th.mat-mdc-table-sticky {
		top: 40px !important;
	}

	.table-container {
		padding-bottom: calc(var(--vis-gu) * 0.5);
		min-height: 78px;
		overflow-x: auto;

		.hidden-row {
			display: none;
			visibility: hidden;
		}

		tr.changed {
			td:first-of-type {
				border-left-style: solid;
				border-left-width: 3px;
				border-left-color: var(--vis-color-accent);
			}
		}

		tr.changed-error {
			td:first-of-type {
				border-left-style: dotted;
				border-left-width: 3px;
				border-left-color: var(--vis-color-error);
			}
		}

		&.no-wrap tbody td {
			white-space: pre;
		}

		&:not([dynamicheight]),
		&[dynamicheight='false'] {
			overflow-y: auto;
			height: 500px;
		}

		&[nodata='true'] {
			height: 100px;
			text-align: center;

			&::after {
				content: 'No data.';
			}
		}

		[vertical-line]:not([vertical-line='false']) {
			border-right-color: var(--vis-color-interactive-element-bg-default-hovered);
			border-right-width: calc(var(--vis-gu) * 0.125);
			border-right-style: solid;
		}

		[top-header] {
			text-align: center;
			border-bottom: none;
		}
	}

	/*
  *sets the Background for sticky columns in table
*/
	.mat-mdc-table-sticky {
		transition: background 0.3s;
		background: var(--vis-color-second-hierarchy-bg-default-enabled) !important;

		&[hierarchy='first'] {
			background: var(--vis-color-first-hierarchy-bg-default-enabled) !important;
		}

		&[hierarchy='second'] {
			background: var(--vis-color-second-hierarchy-bg-default-enabled) !important;
		}

		&[hierarchy='third'] {
			background: var(--vis-color-third-hierarchy-bg-default-enabled) !important;
		}
	}

	tbody {
		tr.selected .mat-mdc-table-sticky,
		tr:hover:not(.col-no-hover) .mat-mdc-table-sticky {
			background: var(--vis-color-third-hierarchy-bg-default-enabled) !important;
			transition: none;
		}

		tr.mat-mdc-row {
			// Hide a bookmark icon and show only on hover on a row
			.bookmark-action__icon:not(.added) {
				opacity: 0;
				transition: opacity 200ms ease-in;
			}

			&:hover:not(.col-no-hover) {
				.bookmark-action__icon:not(.added) {
					opacity: 1;
				}
			}
		}
	}

	// Special "Action" column in tables
	td[actions] div {
		display: grid;

		*[actionpos='1'] {
			grid-column: 1;
		}

		*[actionpos='2'] {
			grid-column: 2;
		}

		*[actionpos='3'] {
			grid-column: 3;
		}

		*[actionpos='4'] {
			grid-column: 4;
		}

		*[actionpos='5'] {
			grid-column: 5;
		}
	}

	$icon-width: 30px;

	td[actions='1'] div {
		grid-template-columns: repeat(1, $icon-width);
	}

	td[actions='2'] div {
		grid-template-columns: repeat(2, $icon-width);
	}

	td[actions='3'] div {
		grid-template-columns: repeat(3, $icon-width);
	}

	td[actions='4'] div {
		grid-template-columns: repeat(4, $icon-width);
	}

	td[actions='5'] div {
		grid-template-columns: repeat(5, $icon-width);
	}
}

@mixin typography($theme) {
	$typography-config: mat.m2-get-typography-config($theme);

	.mat-mdc-table,
	html[theme='dark'] .mat-mdc-table {
		font: var(--vis-typography-body);
	}

	@if isDarkTheme($theme) {
		.mat-mdc-table {
			font: var(--vis-typography-body);
		}
	}
}

@mixin theme($theme) {
	$color-config: mat.m2-get-color-config($theme);

	@if $color-config != null {
		@include color($theme);
	}

	$typography-config: mat.m2-get-typography-config($theme);

	@if $typography-config != null {
		@include typography($theme);
	}
}
