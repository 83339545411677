@use 'sass:map';
@use '@angular/material' as mat;
@import '../material-utils';

@mixin color($theme) {
	$color-config: mat.m2-get-color-config($theme);
	$primary-palette: map.get($color-config, 'primary');
	$highlight-color: mat.m2-get-color-from-palette($primary-palette, 400);
	$highlight-color-hover: mat.m2-get-color-from-palette($primary-palette, 200);

	.mat-mdc-menu-panel {
		--mat-menu-container-shape: var(--vis-border-radius-default);
		--mat-menu-divider-bottom-spacing: 0;
		--mat-menu-divider-top-spacing: 0;
		--mat-menu-item-spacing: var(--vis-gu);
		--mat-menu-item-icon-size: calc(var(--vis-gu) * 2);
		--mat-menu-item-leading-spacing: var(--vis-gu);
		--mat-menu-item-trailing-spacing: var(--vis-gu);
		--mat-menu-item-with-icon-size: calc(var(--vis-gu) * 2);
		--mat-menu-item-with-icon-leading-spacing: calc(var(--vis-gu) * 1);
		--mat-menu-item-with-icon-trailing-spacing: calc(var(--vis-gu) * 1);
		--mat-menu-item-label-text-color: var(--vis-color-text-default);
		--mat-menu-item-icon-color: currentColor;
		--mat-menu-item-hover-state-layer-color: var(--vis-color-second-hierarchy-bg-default-hovered);
		--mat-menu-item-focus-state-layer-color: var(--vis-color-second-hierarchy-bg-active-pressed);
		--mat-menu-container-color: var(--vis-color-second-hierarchy-bg-default-enabled);
		--mat-menu-divider-color: var(--vis-color-text-divider);

		border: 1px solid var(--vis-border-color);

		.mat-mdc-menu-item {
			min-height: calc(var(--vis-gu) * 4);

			&[disabled] {
				opacity: var(--vis-disabled-opacity);
			}

			.mat-icon {
				font-size: var(--mat-menu-item-with-icon-size);
			}
		}

		.mat-mdc-menu-content {
			padding: 0;
		}
	}
}

@mixin typography($theme) {
}

@mixin theme($theme) {
	$color-config: mat.m2-get-color-config($theme);

	@if $color-config != null {
		@include color($theme);
	}

	$typography-config: mat.m2-get-typography-config($theme);

	@if $typography-config != null {
		@include typography($theme);
	}
}
