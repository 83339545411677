@use 'sass:map';
@use '@angular/material' as mat;
@import '../material-utils';

@mixin color($theme) {
	/*
* Paginator
*/
	.mat-mdc-paginator {
		background: inherit !important;
		font: var(--vis-typography-body);
		white-space: nowrap;

		.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
			min-height: 24px;
			padding: 0 !important;
		}

		.mat-mdc-icon-button .mat-mdc-button-persistent-ripple::before {
			display: none;
		}
	}

	.mat-mdc-paginator-page-size-select {
		width: 60px !important;
	}

	.mat-mdc-paginator .mat-mdc-icon-button.mat-mdc-button-base {
		width: 40px !important;
		height: 40px !important;
		padding: 0 !important;
		line-height: 40px;

		svg {
			width: 28px;
			height: 40px;
		}
	}

	.mat-mdc-paginator-container {
		min-height: 40px;
	}
}

@mixin typography($theme) {
}

@mixin theme($theme) {
	$color-config: mat.m2-get-color-config($theme);

	@if $color-config != null {
		@include color($theme);
	}

	$typography-config: mat.m2-get-typography-config($theme);

	@if $typography-config != null {
		@include typography($theme);
	}
}
