@use 'sass:map';
@use '@angular/material' as mat;
@import '../material-utils';

@mixin color($theme) {
	$color-config: mat.m2-get-color-config($theme);
	$primary-palette: map.get($color-config, 'primary');
	$highlight-color: mat.m2-get-color-from-palette($primary-palette, 400);
	$highlight-color-hover: mat.m2-get-color-from-palette($primary-palette, 200);

	button.mdc-button {
		padding: 0 var(--vis-button-horizontal-padding);
		border: 1px solid var(--vis-border-color);
		min-width: var(--vis-button-min-width);

		&.mat-primary {
			--mdc-filled-button-container-color: var(--vis-color-gs-100);
			--mdc-filled-button-label-text-color: var(--vis-color-text-default);
			--mat-filled-button-state-layer-color: (--vis-color-text-default);
			--mat-filled-button-ripple-color: transparent;

			&:not(:disabled) {
				background: var(--vis-color-interactive-element-bg-default-enabled);
			}

			&:hover {
				background: var(--vis-color-interactive-element-bg-default-hovered);
			}

			&[disabled] {
				--mdc-filled-button-disabled-label-text-color: var(--vis-color-text-default);
				--mdc-filled-button-disabled-container-color: var(
					--vis-color-interactive-element-bg-default-disabled
				);
				opacity: var(--vis-disabled-opacity);
			}
		}

		&.mat-accent {
			--mdc-filled-button-container-color: var(--vis-color-interactive);
			--mdc-filled-button-label-text-color: var(--vis-color-text-on-dark-bg);
			border-color: var(--vis-color-gs-120);
			font-weight: 600;

			&[disabled] {
				--mdc-filled-button-disabled-container-color: var(--vis-color-interactive);
				--mdc-filled-button-disabled-label-text-color: var(--vis-color-text-on-dark-bg);
				border-color: var(--vis-color-ultradark-gray);
				opacity: var(--vis-disabled-opacity);
			}
		}

		&.mat-basic {
			--mdc-filled-button-label-text-color: var(--vis-color-text-on-dark-bg);
			--mdc-filled-button-container-color: var(
				--vis-color-interactive-element-bg-activated-enabled
			);
			font-weight: 600;
			border-color: var(--vis-color-semidark);

			&:hover {
				background: $highlight-color-hover;
			}

			&[disabled] {
				--mdc-filled-button-disabled-container-color: var(
					--vis-color-interactive-element-bg-activated-enabled
				);
				--mdc-filled-button-disabled-label-text-color: var(--vis-color-text-on-dark-bg);
				border-color: var(--vis-border-color-hover);
				opacity: var(--vis-disabled-opacity);
			}
		}

		.mdc-button__label {
			display: flex;
			align-items: center;
			flex-wrap: nowrap;
			justify-content: center;
			width: calc(var(--vis-button-width, var(--vis-button-default-width)));
		}

		&[disabled] {
			.mdc-button__label {
				opacity: var(--vis-disabled-opacity);
			}
		}

		.vis-button__content {
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;

			&:not(:first-child) {
				margin-left: var(--vis-gu);
			}
		}

		vis-icon {
			line-height: 0;
			flex-shrink: 0;
		}

		&.vis-button_icon-only {
			--vis-button-width: 48px;
			--vis-button-min-width: 48px;

			.vis-button__content {
				margin-left: 0;
			}
		}

		&:not(.vis-enable-icon-click) {
			vis-icon,
			.vis-button__content,
			.icon {
				pointer-events: none;
			}
		}
	}

	button.mat-mdc-raised-button.mat-primary,
	button.mat-mdc-unelevated-button.mat-unthemed,
	button.mat-mdc-unelevated-button.mat-primary {
		--mat-mdc-button-persistent-ripple-color: transparent;
		--mat-mdc-button-ripple-color: transparent;
		transition: none;
	}

	.mdc-button,
	.mdc-fab--extended {
		width: var(--vis-button-width, var(--vis-button-default-width));
	}

	@if isDarkTheme($theme) {
		button.mdc-button {
			&.mat-basic {
				&,
				&[disabled] {
					border-color: var(--vis-color-gs-120);
				}

				&:hover {
					background-color: darken($highlight-color, 7%) !important;
				}
			}

			&.mat-accent {
				&,
				&[disabled] {
					border-color: var(--vis-color-gs-120);
				}
			}
		}
	}

	.mdc-icon-button__ripple {
		display: none !important;
	}

	button.mdc-icon-button {
		&,
		&:hover {
			--mdc-icon-button-icon-color: var(--vis-color-text-default);
		}

		&:hover {
			opacity: var(--vis-selection-opacity);
		}
	}
}

@mixin typography($theme) {
	button.mdc-button {
		letter-spacing: 0;
		font: var(--vis-typography-body);
	}
}

@mixin theme($theme) {
	$color-config: mat.m2-get-color-config($theme);

	@if $color-config != null {
		@include color($theme);
	}

	$typography-config: mat.m2-get-typography-config($theme);

	@if $typography-config != null {
		@include typography($theme);
	}
}
