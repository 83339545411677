@use 'sass:map';
@use '@angular/material' as mat;
@import '../material-utils';

@mixin color($theme) {
	$color-config: mat.m2-get-color-config($theme);

	.mat-mdc-radio-button.mat-mdc-radio-button {
		--mdc-radio-state-layer-size: calc(var(--vis-gu) * 2);

		--mdc-radio-disabled-selected-icon-color: var(--vis-border-color-hover);
		--mdc-radio-disabled-unselected-icon-color: var(--vis-border-color);

		--mdc-radio-unselected-hover-icon-color: var(--vis-border-color);
		--mdc-radio-unselected-icon-color: var(--vis-border-color);
		--mdc-radio-unselected-pressed-icon-color: var(--vis-border-color);

		--mdc-radio-selected-focus-icon-color: var(--vis-border-color-hover);
		--mdc-radio-selected-hover-icon-color: var(--vis-border-color-hover);
		--mdc-radio-selected-icon-color: var(--vis-border-color-hover);
		--mdc-radio-selected-pressed-icon-color: var(--vis-border-color-hover);

		--mat-radio-ripple-color: transparent;
		--mat-radio-checked-ripple-color: transparent;
		--mat-radio-disabled-label-color: var(--vis-color-text-default);

		--mdc-radio-disabled-selected-icon-opacity: 1;
		--mdc-radio-disabled-unselected-icon-opacity: 1;

		display: block;

		mat-radio-group {
			display: block;
		}

		.mdc-radio {
			width: var(--mdc-radio-state-layer-size);
			height: var(--mdc-radio-state-layer-size);
		}

		.mdc-radio__background {
			width: var(--mdc-radio-state-layer-size);
			height: var(--mdc-radio-state-layer-size);
		}

		.mdc-radio__outer-circle {
			border-width: 1px;
			background: var(--vis-color-interactive-element-bg-default-enabled);
		}

		.mdc-radio__inner-circle {
			border-width: var(--vis-gu);
			border-color: var(--vis-color-text-on-dark-bg) !important;
		}

		.mdc-radio--disabled {
			&,
			& + .mdc-label {
				opacity: var(--vis-disabled-opacity);
			}
		}

		// Background in selected state
		.mdc-radio
			.mdc-radio__native-control:enabled:checked
			+ .mdc-radio__background
			.mdc-radio__outer-circle {
			background: var(--vis-color-interactive-element-bg-activated-enabled);
		}

		// Background in selected hovered state
		.mdc-radio:hover
			.mdc-radio__native-control:enabled:checked
			+ .mdc-radio__background
			.mdc-radio__outer-circle {
			background: var(--vis-color-interactive-element-bg-activated-hovered);
		}

		// Background in selected disabled state
		.mdc-radio
			.mdc-radio__native-control:disabled:checked
			+ .mdc-radio__background
			.mdc-radio__outer-circle {
			background: var(--vis-color-interactive-element-bg-activated-enabled);
		}

		// Background in unselected hovered state
		.mdc-radio:hover
			.mdc-radio__native-control:enabled:not(:checked)
			+ .mdc-radio__background
			.mdc-radio__outer-circle {
			background: var(--vis-color-interactive-element-bg-default-hovered);
		}

		.mdc-form-field {
			color: var(--vis-color-text-default);

			&:hover {
				// Background in selected hovered state
				.mdc-radio
					.mdc-radio__native-control:enabled:checked
					+ .mdc-radio__background
					.mdc-radio__outer-circle {
					background: var(--vis-color-interactive-element-bg-activated-hovered);
				}

				// Background in unselected hovered state
				.mdc-radio
					.mdc-radio__native-control:enabled:not(:checked)
					+ .mdc-radio__background
					.mdc-radio__outer-circle {
					background: var(--vis-color-interactive-element-bg-default-hovered);
				}
			}
		}

		.mdc-radio:not(.mdc-radio--disabled) + label:hover {
			cursor: pointer;
		}
	}
}

@mixin typography($theme) {
	.mat-mdc-radio-button {
		line-height: calc(var(--vis-gu) * 2.7);

		.mdc-form-field {
			font-size: var(--vis-body-font-size);
			line-height: calc(var(--vis-gu) * 2);
		}
	}
}

@mixin theme($theme) {
	$color-config: mat.m2-get-color-config($theme);

	@if $color-config != null {
		@include color($theme);
	}

	$typography-config: mat.m2-get-typography-config($theme);

	@if $typography-config != null {
		@include typography($theme);
	}
}
