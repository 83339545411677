@use 'sass:map';
@use '@angular/material' as mat;
@import '../material-utils';

@mixin color($theme) {
	$color-config: mat.m2-get-color-config($theme);

	.mdc-checkbox.mdc-checkbox {
		--mdc-checkbox-state-layer-size: 16px;
		// Checkmark
		--mdc-checkbox-selected-checkmark-color: var(--vis-color-text-on-dark-bg);

		// Border and Background
		// Disabled
		--mdc-checkbox-disabled-unselected-icon-color: var(--vis-border-color);
		--mdc-checkbox-disabled-selected-icon-color: var(--vis-border-color-hover);
		// Selected
		--mdc-checkbox-selected-focus-icon-color: var(
			--vis-color-interactive-element-bg-activated-enabled
		);
		--mdc-checkbox-selected-hover-icon-color: var(
			--vis-color-interactive-element-bg-activated-hovered
		);
		--mdc-checkbox-selected-icon-color: var(--vis-color-interactive-element-bg-activated-enabled);
		--mdc-checkbox-selected-pressed-icon-color: var(
			--vis-color-interactive-element-bg-activated-pressed
		);
		// Unselected
		--mdc-checkbox-unselected-focus-icon-color: var(--vis-border-color);
		--mdc-checkbox-unselected-hover-icon-color: var(--vis-border-color);
		--mdc-checkbox-unselected-icon-color: var(--vis-border-color);
		--mdc-checkbox-unselected-pressed-icon-color: var(--vis-border-color);

		--mdc-checkbox-selected-focus-state-layer-color: var(
			--vis-color-interactive-element-bg-activated-enabled
		);
		--mdc-checkbox-selected-hover-state-layer-color: var(--vis-border-color-hover);
		--mdc-checkbox-selected-pressed-state-layer-color: var(
			--vis-color-interactive-element-bg-activated-enabled
		);

		--mdc-checkbox-unselected-focus-state-layer-color: var(
			--vis-color-interactive-element-bg-default-enabled
		);
		--mdc-checkbox-unselected-hover-state-layer-color: var(
			--vis-color-interactive-element-bg-default-enabled
		);
		--mdc-checkbox-unselected-pressed-state-layer-color: var(
			--vis-color-interactive-element-bg-default-enabled
		);

		width: var(--mdc-checkbox-state-layer-size);
		height: var(--mdc-checkbox-state-layer-size);
		flex: 0 0 var(--mdc-checkbox-state-layer-size);

		.mdc-checkbox__background {
			border-width: 1px;
			box-sizing: border-box;
			width: var(--mdc-checkbox-state-layer-size);
			height: var(--mdc-checkbox-state-layer-size);
			top: calc((var(--mdc-checkbox-state-layer-size) - var(--mdc-checkbox-state-layer-size)) / 2);
			left: calc((var(--mdc-checkbox-state-layer-size) - var(--mdc-checkbox-state-layer-size)) / 2);
			border-radius: 2.5px;

			.mdc-checkbox__checkmark {
				width: calc(var(--vis-gu) * 0.8);
				height: calc(var(--vis-gu) * 0.8);
				top: 50%;
				left: 50%;
				translate: -50% -50%;
			}

			.mdc-checkbox__checkmark-path {
				width: 50%;
				stroke-width: 6px;
			}
		}

		.mdc-checkbox__ripple {
			display: none;
		}

		// Border and Background colors in different states
		.mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not(
				[data-indeterminate='true']
			)
			~ .mdc-checkbox__background {
			background: var(--vis-color-interactive-element-bg-default-enabled);
		}

		.mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background,
		.mdc-checkbox__native-control:enabled:indeterminate ~ .mdc-checkbox__background,
		.mdc-checkbox__native-control[data-indeterminate='true']:enabled ~ .mdc-checkbox__background {
			border-color: var(--vis-border-color-hover);
		}

		.mdc-checkbox__native-control:enabled:focus:checked ~ .mdc-checkbox__background,
		.mdc-checkbox__native-control:enabled:focus:indeterminate ~ .mdc-checkbox__background {
			border-color: var(--vis-border-color-hover);
		}

		&:not(:disabled):active
			.mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not(
				[data-indeterminate='true']
			)
			~ .mdc-checkbox__background {
			border-color: var(--vis-border-color-hover);
		}

		&:hover {
			.mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not(
					[data-indeterminate='true']
				)
				~ .mdc-checkbox__background {
				background: var(--vis-color-interactive-element-bg-default-hovered);
			}

			&:not(:active) .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background,
			&:not(:active)
				.mdc-checkbox__native-control:enabled:indeterminate
				~ .mdc-checkbox__background,
			&:not(:active)
				.mdc-checkbox__native-control[data-indeterminate='true']:enabled
				~ .mdc-checkbox__background {
				border-color: var(--vis-border-color-hover);
				background: var(--vis-color-interactive-element-bg-activated-hovered);
			}
		}

		&:not(:disabled):active
			.mdc-checkbox__native-control:enabled:checked
			~ .mdc-checkbox__background,
		&:not(:disabled):active
			.mdc-checkbox__native-control:enabled:indeterminate
			~ .mdc-checkbox__background,
		&:not(:disabled):active
			.mdc-checkbox__native-control[data-indeterminate='true']:enabled
			~ .mdc-checkbox__background,
		&:not(:disabled):active
			.mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not(
				[data-indeterminate='true']
			)
			~ .mdc-checkbox__background {
			border-color: var(--vis-border-color-hover);
		}

		&:not(:disabled):active
			.mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not(
				[data-indeterminate='true']
			)
			~ .mdc-checkbox__background {
			border-color: var(--vis-border-color);
			background: var(--vis-color-interactive-element-bg-default-pressed);
		}

		.mdc-checkbox__native-control[disabled]:checked ~ .mdc-checkbox__background {
			border-color: var(--vis-border-color-hover);
			background: var(--vis-color-interactive-element-bg-activated-enabled);
		}
	}

	.mdc-checkbox__native-control[disabled]:not(:checked):not(:indeterminate):not(
			[data-indeterminate='true']
		)
		~ .mdc-checkbox__background {
		background-color: var(--vis-color-interactive-element-bg-default-enabled) !important;
	}

	.mdc-checkbox__native-control[disabled]:checked ~ .mdc-checkbox__background,
	.mdc-checkbox__native-control[disabled]:indeterminate ~ .mdc-checkbox__background {
		border-color: var(--vis-border-color-hover);
	}

	.mdc-checkbox__native-control:indeterminate ~ .mdc-checkbox__background .mdc-checkbox__mixedmark {
		width: var(--vis-gu);
		border-width: calc(var(--vis-gu) / 2);
	}

	.mdc-checkbox__native-control.mdc-checkbox__native-control[disabled]:indeterminate
		~ .mdc-checkbox__background {
		border-color: var(--vis-border-color-hover);
		background-color: var(--vis-color-interactive-element-bg-activated-enabled);
	}

	.mdc-checkbox__mixedmark {
		border-radius: 1px;
	}

	.mat-mdc-checkbox.mat-mdc-checkbox {
		display: block;

		&:hover {
			.mdc-label {
				cursor: pointer;
			}

			.mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not(
					[data-indeterminate='true']
				)
				~ .mdc-checkbox__background {
				background: var(--vis-color-interactive-element-bg-default-hovered);
			}

			&:not(:active) .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background,
			&:not(:active)
				.mdc-checkbox__native-control:enabled:indeterminate
				~ .mdc-checkbox__background,
			&:not(:active)
				.mdc-checkbox__native-control[data-indeterminate='true']:enabled
				~ .mdc-checkbox__background {
				border-color: var(--vis-border-color-hover);
				background: var(--vis-color-interactive-element-bg-activated-hovered);
			}
		}

		&.mdc-checkbox--disabled {
			opacity: var(--vis-disabled-opacity);
		}

		// Label {
		.mdc-form-field {
			flex: 1;
			width: 100%;

			label {
				color: var(--vis-color-text-default);
				padding-left: var(--vis-gu);
				flex: 1;
			}
		}
	}
}

@mixin typography($theme) {
	.mat-mdc-checkbox {
		line-height: calc(var(--vis-gu) * 2.7);

		.mdc-form-field {
			font-size: var(--vis-body-font-size);
			line-height: calc(var(--vis-gu) * 2);
		}
	}
}

@mixin theme($theme) {
	$color-config: mat.m2-get-color-config($theme);

	@if $color-config != null {
		@include color($theme);
	}

	$typography-config: mat.m2-get-typography-config($theme);

	@if $typography-config != null {
		@include typography($theme);
	}
}
