@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
	$color-config: mat.m2-get-color-config($theme);

	.mat-mdc-chip.mat-mdc-chip.mat-mdc-standard-chip {
		--mdc-chip-label-text-color: var(--vis-color-text-default);
		--mdc-chip-selected-label-text-color: var(--vis-color-text-on-dark-bg);
		--mdc-chip-disabled-label-text-color: var(--vis-color-text-default);
		--mdc-chip-elevated-container-color: var(--vis-color-interactive-element-bg-default-enabled);
		--mdc-chip-elevated-disabled-container-color: var(
			--vis-color-interactive-element-bg-default-enabled
		);
		--mdc-chip-elevated-selected-container-color: var(
			--vis-color-interactive-element-bg-activated-enabled
		);
		--mdc-chip-focus-state-layer-color: var(--vis-color-interactive-element-bg-default-hovered);
		--mdc-chip-focus-state-layer-opacity: 1;
		--mdc-chip-with-icon-icon-color: var(--vis-color-text-default);
		--mdc-chip-with-icon-disabled-icon-color: var(--vis-color-text-default);
		--mdc-chip-with-icon-selected-icon-color: var(--vis-color-text-default);
		--mdc-chip-with-trailing-icon-disabled-trailing-icon-color: var(--vis-color-text-default);
		--mdc-chip-with-trailing-icon-trailing-icon-color: var(--vis-color-text-default);

		color: var(--vis-color-text-default);
		border: 1px solid var(--vis-border-color);

		&:not(.no-hover):not(.mdc-evolution-chip--selected):hover {
			background-color: var(--vis-color-interactive-element-bg-default-hovered);
		}

		.mat-mdc-chip-ripple,
		.mat-mdc-chip-focus-overlay {
			display: none;
		}

		button.mat-mdc-chip-remove {
			opacity: 1;
			color: var(--vis-color-icon-interactive-bg-enabled);
			padding: 0 var(--vis-gu) 0 calc(var(--vis-gu) / 2);

			&:hover {
				opacity: var(--vis-selection-opacity);
			}
		}

		&:not(.mdc-evolution-chip--disabled):not(.mat-mdc-chip-selected) {
			--mdc-chip-elevated-container-color: var(
				var(--vis-color-interactive-element-bg-default-enabled)
			);
		}

		&.mdc-evolution-chip--selected,
		&.mat-mdc-chip-selected.mat-accent,
		&.mat-mdc-chip-highlighted.mat-accent {
			--mdc-chip-label-text-color: var(--vis-color-text-on-dark-bg);
			--mdc-chip-disabled-label-text-color: var(--vis-color-text-on-dark-bg);
			--mdc-chip-elevated-container-color: var(
				--vis-color-interactive-element-bg-activated-enabled
			);
			--mdc-chip-elevated-disabled-container-color: var(
				--vis-color-interactive-element-bg-activated-enabled
			);
			border-color: var(--vis-color-interactive-element-thin-line-activated-enabled);
		}
	}

	.mat-mdc-chip {
		&.mat-mdc-standard-chip {
			display: inline-flex;
			align-items: center;
			justify-content: center;
			box-sizing: border-box;
			min-width: calc(var(--vis-gu) * 6);
			height: calc(var(--vis-gu) * 4);
			cursor: default;

			&:not(.closable) {
				.mdc-evolution-chip__action {
					width: 100%;
					padding-left: calc(var(--vis-gu) * 1.5);
				}
			}

			&.mdc-evolution-chip--disabled {
				opacity: var(--vis-disabled-opacity);
			}

			.mdc-evolution-chip__graphic {
				display: none;
			}
		}

		&.size-s {
			height: calc(var(--vis-gu) * 3);

			.mdc-evolution-chip__action--trailing.mat-mdc-chip-remove {
				padding: 0 calc(var(--vis-gu) / 2);
			}
		}
	}

	.mat-mdc-standard-chip.mdc-evolution-chip--with-trailing-action
		.mdc-evolution-chip__action--primary {
		padding-left: calc(var(--vis-gu) * 2.5);
		padding-right: calc(var(--vis-gu) * 2.5);
	}
}

@mixin typography($theme) {
	$typography-config: mat.m2-get-typography-config($theme);
	$font-family: mat.m2-font-family($typography-config);

	.mat-mdc-standard-chip {
		.mdc-evolution-chip__text-label {
			font-size: mat.m2-font-size($typography-config, 'body-1');
			letter-spacing: 0;
			display: flex;
			align-items: center;

			& > :empty + vis-state-dot {
				margin-left: var(--vis-gu);
			}
		}

		&.size-s .mdc-evolution-chip__text-label {
			--mdc-chip-label-text-size: var(--vis-caption-font-size);
		}

		&.mdc-evolution-chip--with-trailing-action
			.mdc-evolution-chip__action--trailing.mat-mdc-chip-remove {
			font-size: mat.m2-font-size($typography-config, 'body-1');

			& > mat-icon {
				line-height: calc(var(--vis-gu) * 2.25);

				&:hover {
					opacity: var(--vis-disabled-opacity);
				}
			}
		}
	}
}

@mixin theme($theme) {
	$color-config: mat.m2-get-color-config($theme);

	@if $color-config != null {
		@include color($theme);
	}

	$typography-config: mat.m2-get-typography-config($theme);

	@if $typography-config != null {
		@include typography($theme);
	}
}
