@import '@zeiss/ng-vis-common/styles/global';

// Animations must be in global stylesheets starts from Angular v15

@keyframes fade-in {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@keyframes from-bottom-left {
	from {
		transform: translate(-20px, 20px);
	}
	to {
		transform: translate(0px, 0px);
	}
}

@keyframes from-top-right {
	from {
		transform: translate(20px, -20px);
	}
	to {
		transform: translate(0px, 0px);
	}
}

@keyframes from-bottom-right {
	from {
		transform: translate(20px, 20px);
	}
	to {
		transform: translate(0px, 0px);
	}
}

@keyframes slide-down-enter {
	from {
		opacity: 0;
		transform: translateY(-30px);
	}
	to {
		opacity: 1;
		transform: translateY(0px);
	}
}

@keyframes slide-down-leave {
	from {
		opacity: 1;
		transform: translateY(0px);
	}
	to {
		opacity: 0;
		transform: translateY(30px);
	}
}

@keyframes slide-up-enter {
	from {
		opacity: 0;
		transform: translateY(30px);
	}
	to {
		opacity: 1;
		transform: translateY(0px);
	}
}

@keyframes slide-up-leave {
	from {
		opacity: 1;
		transform: translateY(0px);
	}
	to {
		opacity: 0;
		transform: translateY(-30px);
	}
}

// Common styles
html {
	overflow: hidden;
}

body {
	margin: 0;
	width: 100%;
	height: var(--main-height);
}

:root {
	// TODO: workaround for zui 1.25.5 as they somehow removed the following variables
	--zui-typography-body: var(--zui-body-font-style) var(--zui-body-font-weight) var(--zui-body-font-size) /
			var(--zui-body-line-height) var(--zui-font-family),
		sans-serif;
	--zui-typography-h0: var(--zui-h0-font-style) var(--zui-h0-font-weight) var(--zui-h0-font-size) /
			var(--zui-h0-line-height) var(--zui-font-family),
		sans-serif;
	--zui-typography-h1: var(--zui-h1-font-style) var(--zui-h1-font-weight) var(--zui-h1-font-size) /
			var(--zui-h1-line-height) var(--zui-font-family),
		sans-serif;
	--zui-typography-h2: var(--zui-h2-font-style) var(--zui-h2-font-weight) var(--zui-h2-font-size) /
			var(--zui-h2-line-height) var(--zui-font-family),
		sans-serif;
	--zui-typography-h3: var(--zui-h3-font-style) var(--zui-h3-font-weight) var(--zui-h3-font-size) /
			var(--zui-h3-line-height) var(--zui-font-family),
		sans-serif;
	--zui-typography-h4: var(--zui-h4-font-style) var(--zui-h4-font-weight) var(--zui-h4-font-size) /
			var(--zui-h4-line-height) var(--zui-font-family),
		sans-serif;
	--zui-typography-subtitle1: var(--zui-subtitle1-font-style) var(--zui-subtitle1-font-weight)
			var(--zui-subtitle1-font-size) / var(--zui-subtitle1-line-height) var(--zui-font-family),
		sans-serif;
	--zui-typography-subtitle2: var(--zui-subtitle2-font-style) var(--zui-subtitle2-font-weight)
			var(--zui-subtitle2-font-size) / var(--zui-subtitle2-line-height) var(--zui-font-family),
		sans-serif;
	--zui-typography-caption: var(--zui-caption-font-style) var(--zui-caption-font-weight) var(--zui-caption-font-size) /
			var(--zui-caption-line-height) var(--zui-font-family),
		sans-serif;
	--zui-typography-label1: var(--zui-label1-font-style) var(--zui-label1-font-weight) var(--zui-label1-font-size) /
			var(--zui-label1-line-height) var(--zui-font-family),
		sans-serif;
	--zui-typography-label2: var(--zui-label2-font-style) var(--zui-label2-font-weight) var(--zui-label2-font-size) /
			var(--zui-label2-line-height) var(--zui-font-family),
		sans-serif;
}

.vis-nowrap {
	white-space: pre;
}

.vis-wrap {
	white-space: normal;
	word-break: break-word;
}

.vis-wrap-all {
	white-space: normal;
	word-break: break-all;
}

.vis-flex-spacer {
	flex: 1 0 0;
}

.no-focus {
	outline: 0 !important;
}

// Colorize scrollbars
::-webkit-scrollbar {
	width: calc(var(--zui-gu) * 0.75);
	height: calc(var(--zui-gu) * 0.75);
}
::-webkit-scrollbar-track {
	opacity: var(--zui-scrollbar-track-opacity);
}
::-webkit-scrollbar-thumb {
	background: var(--zui-color-scrollbar-track);
	border-radius: calc(var(--zui-gu) * 1);
}
::-webkit-scrollbar-thumb:hover {
	background: var(--zui-color-scrollbar-handle);
}

// Blurry overlay
// Color per theme
html[theme='light'] .vis-overlay-blur {
	--vis-overlay-blur-bg: var(--zui-color-gs-10);
}
html[theme='dark'] .vis-overlay-blur {
	--vis-overlay-blur-bg: var(--zui-color-gs-105);
}

// Color exceptions per element (overwrites theme)
vis-headerbar-portal-slot,
zui-headerbar {
	.vis-overlay-blur {
		--vis-overlay-blur-bg: var(--zui-color-gs-10) !important;
	}
}
zui-menubar {
	.vis-overlay-blur {
		--vis-overlay-blur-bg: var(--zui-color-gs-105) !important;
	}
}

// Overwrite size of all vis-code-editors in zui-dialogbox
zui-dialogbox vis-code-editor {
	min-height: calc(var(--zui-gu) * 60);
	min-width: calc(var(--zui-gu) * 80);
	display: block;
}

// KTD Grid
ktd-grid {
	.ktd-grid-item-placeholder {
		background-color: transparent !important;
		opacity: 1 !important;
		z-index: 4 !important;
		border: 1px dashed var(--zui-color-info);
	}

	ktd-grid-item {
		[grid-item-content] {
			padding: calc(var(--zui-gu) * 0.5);
			height: 100%;
		}

		&[ng-reflect-draggable='true'] {
			cursor: grab;

			&.ktd-grid-item-dragging {
				cursor: grabbing;
			}
		}

		&[removable='true'] [grid-item-remove-handle] {
			color: var(--zui-color-error);
			position: absolute;
			cursor: pointer;
			top: calc(var(--zui-gu) * 0.5);
			right: calc(var(--zui-gu) * 0.5);
		}

		.grid-item-resize-icon {
			color: var(--zui-color-info) !important;
			height: calc(var(--zui-gu) * 2) !important;
			width: calc(var(--zui-gu) * 2) !important;
			justify-content: center !important;
			align-items: center !important;
			bottom: calc(var(--zui-gu) * 0.5) !important;
			right: calc(var(--zui-gu) * 0.5) !important;

			&::after {
				position: relative !important;
				height: calc(100% - (var(--zui-gu) * 0.25)) !important;
				width: calc(100% - (var(--zui-gu) * 0.25)) !important;
				box-sizing: border-box;
				bottom: 0 !important;
				right: 0 !important;
				display: flex;
			}
		}

		&[ng-reflect-resizable='false'] .grid-item-resize-icon {
			display: none !important;
		}
	}
}

//Info Indicator
.info-indicator {
	position: absolute;
	right: 0;
	top: 0;
	height: 0;
	border-right: 7.5px solid;
	border-bottom: 7.5px solid transparent;
	border-left: 7.5px solid transparent;
	border-top: 7.5px solid;

	[color='error'],
	[color='0'] {
		border-right-color: var(--zui-color-error);
		border-top-color: var(--zui-color-error);
	}

	[color='warning'],
	[color='1'] {
		border-right-color: var(--zui-color-warning);
		border-top-color: var(--zui-color-warning);
	}

	[color='success'],
	[color='2'] {
		border-right-color: var(--zui-color-success);
		border-top-color: var(--zui-color-success);
	}

	[color='info'],
	[color='3'] {
		border-right-color: var(--zui-color-info);
		border-top-color: var(--zui-color-info);
	}

	[color='grey'],
	[color='4'] {
		border-right-color: var(--zui-color-help);
		border-top-color: var(--zui-color-help);
	}
}

// Section Divider
.section-divider-with-icon {
	display: flex;
	align-items: center;
}

.section-divider-with-icon > * {
	margin-right: var(--zui-gu);
}

.section-divider-with-icon > *:first-child {
	margin-left: -20px;
}

.tutorial-dialog-backdrop {
	background: none;
}

[media='mobile'] {
	.main-container {
		padding: var(--zui-gu) 0 !important;
	}

	.breadcrumbs {
		flex-wrap: wrap;
		height: 100%;
		margin: auto;

		span {
			width: 100%;

			vis-icon {
				margin-left: -2px !important;
			}
		}
	}

	zui-headerbar-icon-button,
	zui-headerbar-notification-button {
		--zui-gu: 4px;
		margin: auto;

		zui-state-dot {
			--zui-gu: 8px;
			right: calc(var(--zui-gu) * 0.5);
			top: calc(var(--zui-gu) * 0.25);
		}
	}
}

[media='mobile'],
[media='tablet'] {
	.breadcrumbs {
		flex-wrap: wrap;
		height: 100%;
		margin: auto;
		padding-left: calc(var(--zui-gu) * 2);
	}
}

vis-select-details {
	margin-left: var(--zui-gu);
}

// Swagger UI

#swagger-docs.swagger-ui {
	.wrapper {
		background: var(--zui-color-gs-10);
		margin: 0;
		padding: 20px;
		max-width: 100%;
	}

	.info {
		margin: 0;
		padding: 0;
	}
}

// NgxImageZoom library

.ngx-image-zoom-full-width {
	& .ngxImageZoomContainer {
		width: 100% !important;
		height: 100% !important;
	}

	& .ngxImageZoomThumbnail {
		max-height: 100%;
		width: 100%;
	}

	& .ngxImageZoomFullContainer.ngxImageZoomLensEnabled {
		border-color: var(--zui-color-info) !important;
	}
}

// used by quick-nav to hide the portal temporarily
.vis-headerbar-portal-hidden {
	opacity: 0;
}
