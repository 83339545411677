@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
}

@mixin typography($theme) {
	$typography-config: mat.m2-get-typography-config($theme);
	$font-family: mat.m2-font-family($typography-config);

	.mdc-dialog.mat-mdc-dialog-container {
		--mdc-dialog-container-shape: var(--vis-gu);
		--mdc-dialog-container-color: var(--vis-color-first-hierarchy-bg-default-enabled);
	}
}

@mixin theme($theme) {
	$color-config: mat.m2-get-color-config($theme);

	@if $color-config != null {
		@include color($theme);
	}

	$typography-config: mat.m2-get-typography-config($theme);

	@if $typography-config != null {
		@include typography($theme);
	}
}
