@use 'sass:map';
@use '@angular/material' as mat;
@import '../material-utils';

@mixin color($theme) {
	$color-config: mat.m2-get-color-config($theme);
	$primary-palette: map.get($color-config, 'primary');
	$primary-color: mat.m2-get-color-from-palette($primary-palette, 500);
	$dark-color: mat.m2-get-color-from-palette($primary-palette, 600);
	$light-color: mat.m2-get-color-from-palette($primary-palette, 700);
	$contrast-color: mat.m2-get-color-from-palette($primary-palette, 500-contrast);

	.mat-datepicker-content.mat-datepicker-content {
		--mat-datepicker-calendar-container-shape: calc(var(--vis-gu) / 2);
		--mat-datepicker-calendar-container-touch-shape: calc(var(--vis-gu) / 2);
		--mat-datepicker-calendar-container-elevation-shadow: var(--vis-box-shadow-large);
		--mat-datepicker-calendar-container-touch-elevation-shadow: var(--vis-box-shadow-large);
		--mat-datepicker-calendar-container-background-color: var(
			--vis-color-first-hierarchy-bg-default-enabled
		);
		--mat-datepicker-calendar-container-text-color: var(--vis-color-text-default);

		border: 1px solid var(--vis-border-color);
	}

	.mat-calendar.mat-calendar {
		--mat-datepicker-calendar-date-selected-state-text-color: var(--vis-color-white);
		--mat-datepicker-calendar-date-selected-state-background-color: var(
			--vis-color-interactive-element-bg-activated-enabled
		);
		--mat-datepicker-calendar-date-selected-disabled-state-background-color: var(
			--zui-color-first-hierarchy-bg-default-hovered
		);
		--mat-datepicker-calendar-date-today-selected-state-outline-color: var(
			--vis-color-interactive-element-bg-activated-enabled
		);
		--mat-datepicker-calendar-date-focus-state-background-color: transparent;
		--mat-datepicker-calendar-date-hover-state-background-color: transparent;
		--mat-datepicker-toggle-active-state-icon-color: var(--vis-color-text-default);
		--mat-datepicker-calendar-date-in-range-state-background-color: var(
			--vis-color-interactive-element-bg-activated-hovered
		);
		--mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(
			249,
			171,
			0,
			0.2
		);
		--mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
		--mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
		--mat-datepicker-toggle-icon-color: var(--vis-color-text-default);
		--mat-datepicker-calendar-body-label-text-color: var(--vis-color-text-default);
		--mat-datepicker-calendar-period-button-text-color: var(--vis-color-text-default);
		--mat-datepicker-calendar-period-button-icon-color: var(--vis-color-text-default);
		--mat-datepicker-calendar-navigation-button-icon-color: var(--vis-color-text-default);
		--mat-datepicker-calendar-header-divider-color: transparent;
		--mat-datepicker-calendar-header-text-color: var(--vis-color-text-default);
		--mat-datepicker-calendar-date-today-outline-color: var(--vis-color-text-default);
		--mat-datepicker-calendar-date-today-disabled-state-outline-color: transparent;
		--mat-datepicker-calendar-date-text-color: var(--vis-color-gs-120);
		--mat-datepicker-calendar-date-outline-color: var(--vis-color-gs-40);
		--mat-datepicker-calendar-date-disabled-state-text-color: var(--vis-color-gs-40);
		--mat-datepicker-calendar-date-preview-state-outline-color: var(--vis-color-gs-40);
		--mat-datepicker-range-input-separator-color: transparent;
		--mat-datepicker-range-input-disabled-state-separator-color: transparent;
		--mat-datepicker-range-input-disabled-state-text-color: var(--vis-color-text-default);

		--mat-datepicker-calendar-text-font: var(--vis-font-family);
		--mat-datepicker-calendar-text-size: var(--vis-caption-font-size);
		--mat-datepicker-calendar-body-label-text-size: var(--vis-caption-font-size);
		--mat-datepicker-calendar-body-label-text-weight: 500;
		--mat-datepicker-calendar-period-button-text-size: var(--vis-caption-font-size);
		--mat-datepicker-calendar-period-button-text-weight: 500;
		--mat-datepicker-calendar-header-text-size: var(--vis-caption-font-size);
		--mat-datepicker-calendar-header-text-weight: bold;

		//regular date cell
		.mat-calendar-body-cell-content {
			border-radius: 0 !important;

			&:not(:hover) {
				border-color: transparent;
			}
		}

		.mat-calendar-body-cell.mat-calendar-body-disabled {
			.mat-calendar-body-cell-content {
				border-color: transparent;
				text-decoration: line-through;
			}
		}

		.md-datepicker-input-container {
			width: 200px;
			padding: calc(var(--vis-gu) * 3) calc(var(--vis-gu) * 4) 0 calc(var(--vis-gu) * 4);
		}

		.mat-calendar-header {
			padding: calc(var(--vis-gu) * 3) calc(var(--vis-gu) * 4) var(--vis-gu) calc(var(--vis-gu) * 4);

			button.mdc-button {
				&,
				&:hover {
					border: none;
					padding: 0;
					margin: 0;
				}
			}
		}

		.mat-calendar-table-header th {
			padding-bottom: calc(var(--vis-gu) * 1.25);
		}

		.mat-calendar-content {
			padding: 0 calc(var(--vis-gu) * 4) 0 calc(var(--vis-gu) * 4);
		}

		.mat-calendar-controls {
			margin-top: 0;
			display: flex;
			justify-content: space-between;
			flex-direction: row;

			.mat-calendar-spacer,
			.mdc-button__ripple,
			.mdc-icon-button__ripple {
				display: none;
			}
		}

		.mat-calendar-previous-button {
			order: -5;
		}

		.mat-calendar-body-in-preview.mat-calendar-body-preview-end {
			.mat-calendar-body-cell-preview {
				border-radius: 0;
				border-right: 0;
			}
		}

		.mat-calendar-body-in-range .mat-calendar-body-cell-content {
			color: var(--mat-datepicker-calendar-date-selected-state-text-color);
		}
	}

	@if isDarkTheme($theme) {
		.mat-calendar.mat-calendar {
			--mat-datepicker-calendar-date-selected-state-background-color: var(--vis-color-gs-80);
			--mat-datepicker-calendar-date-disabled-state-text-color: var(--vis-color-gs-80);
			--mat-datepicker-range-input-disabled-state-text-color: var(--vis-color-gs-80);
			--mat-datepicker-calendar-date-outline-color: var(--vis-color-gs-80);
			--mat-datepicker-calendar-date-text-color: var(--vis-color-white);
			--mat-datepicker-calendar-header-text-color: var(--vis-color-gs-50);
			--mat-datepicker-calendar-navigation-button-icon-color: var(--vis-color-gs-50);
			--mdc-text-button-label-text-color: var(--vis-color-gs-50);
			--mat-datepicker-calendar-date-preview-state-outline-color: var(--vis-color-gs-50);
			--mat-datepicker-calendar-date-in-range-state-background-color: var(
				--vis-color-interactive-element-bg-activated-enabled
			);
		}

		.mat-mdc-icon-button:hover {
			--mat-datepicker-calendar-navigation-button-icon-color: var(--vis-color-gs-90);
		}
	}

	.mat-datepicker-toggle {
		&.input-icon {
			button.mat-mdc-button-base.mdc-icon-button {
				width: 18px;
				height: 18px;
				line-height: 18px;
				padding: 0;
			}
		}
	}
}

@mixin typography($theme) {
	$typography-config: mat.m2-get-typography-config($theme);
	$font-family: mat.m2-font-family($typography-config);

	.mat-calendar.mat-calendar {
		&,
		.mat-calendar-body-cell {
			font: var(--vis-typography-caption);
		}
	}
}

@mixin theme($theme) {
	$color-config: mat.m2-get-color-config($theme);

	@if $color-config != null {
		@include color($theme);
	}

	$typography-config: mat.m2-get-typography-config($theme);

	@if $typography-config != null {
		@include typography($theme);
	}
}
